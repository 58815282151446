import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";


import BorderColorIcon from "@mui/icons-material/BorderColor";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge, Collapse, List, useTheme, useMediaQuery } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import { makeStyles } from '@mui/styles';
import usePlans from "../hooks/usePlans";
import Typography from "@mui/material/Typography";
import { ReactComponent as LogbarIcon } from "../assets/logbar.svg";
import { NavLink } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ContactsIcon from '@mui/icons-material/Contacts';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import ReplayIcon from '@mui/icons-material/Replay';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupIcon from '@mui/icons-material/Group';
import CodeIcon from '@mui/icons-material/Code';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
    borderRadius: '8px',
    
  },
   svgIcon2: {
   
    [theme.breakpoints.down('md')]: {
      
      width: "auto",
      height: "100%",
      maxWidth: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      

    },
    
  },
  logo: {
    width: 300,
    height: 300,
    color: theme.palette.primary.main
  },
  icon: {
    color: 'rgb(213, 217, 233)',
    
  },
  listItem: {
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    minWidth: 0,
    boxSizing: 'border-box',
    textAlign: 'left',
    paddingRight: '16px',
    color: 'rgb(213, 217, 233) !important',
    paddingTop: '10px',
    paddingBottom: '10px',
    zIndex: 1201,
    paddingLeft: '24px',
    outline: '0px',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    margin: '0px 0px 4px',
    textDecoration: 'none',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    borderRadius: '8px',
    
    '&:hover': {
      backgroundColor: 'rgba(236, 64, 122, 0.15) !important',
      color: 'rgb(236, 64, 122) !important',
    },
  },
  listItemStyle : {
    margin: '0px',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.334em',
    fontFamily: 'Roboto, sans-serif',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit', 
    width: '102px',
  }
  
}));


function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false); const history = useHistory();
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))


  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();
 

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;

      try {
        const planConfigs = await getPlanCompany(undefined, companyId);

        setShowCampaigns(planConfigs?.plan?.useCampaigns);
        setShowKanban(planConfigs?.plan?.useKanban);
        setShowOpenAi(planConfigs?.plan?.useOpenAi);
        setShowIntegrations(planConfigs?.plan?.useIntegrations);
        setShowSchedules(planConfigs?.plan?.useSchedules);
        setShowInternalChat(planConfigs?.plan?.useInternalChat);
        setShowExternalApi(planConfigs?.plan?.useExternalApi);
      } catch (err) {
        toastError('Erro ao buscar as configurações do plano');
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    const cshowValue = localStorage.getItem("cshow");
    if (cshowValue) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  return (
      
    
    <div onClick={drawerClose}>
      

    {(isMobile && ( <LogbarIcon width="170" height="60" className={classes.svgIcon2}/> ))}
      

      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          

          <ListItemLink
            to="/"
            primary="Dashboard"
            icon={<PieChartIcon style={{ fontSize: 24 }} className={classes.icon} />}
            className={classes.listItem}
          />
        )}
      />

      
      
     

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon size={24} weight="fill"className={classes.icon}/>}
        className={classes.listItem}
        
      />
	  
	  <ListItemLink
        to="/kanban"
        primary={i18n.t("Kanban")}
        icon={<BorderColorIcon size={24} weight="fill" className={classes.icon}/>}
        className={classes.listItem}
      />

      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<FlashOnIcon style={{ fontSize: 24 }} className={classes.icon} />}
        className={classes.listItem}
      />
	  
	  <ListItemLink
        to="/todolist"
        primary={i18n.t("Tarefas")}
        icon={<FormatListNumberedIcon style={{ fontSize: 24 }} className={classes.icon} />}
        className={classes.listItem}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactsIcon style={{ fontSize: 24 }} className={classes.icon}/>}
        className={classes.listItem}
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<CalendarTodayIcon style={{ fontSize: 24 }} className={classes.icon}/>}
        className={classes.listItem}
      />

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon style={{ fontSize: 24 }} className={classes.icon}/>}
        className={classes.listItem}
        
      />

      <ListItemLink
        to="/chats"
        primary={i18n.t("mainDrawer.listItems.chats")}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <ChatBubbleIcon style={{ fontSize: 24 }} className={classes.icon}/>
          </Badge>
          
        }
        className={classes.listItem}
      />

      <ListItemLink
      to='/client-management'
      primary='Gerenciar Clientes'
      icon={<AccountBalanceWalletIcon style={{ fontSize: 24 }} className={classes.icon}/>}
      className={classes.listItem}
      />

      {/* <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpOutlineIcon style={{ fontSize: 24 }} className={classes.icon}/>}
        className={classes.listItem}
      /> */}

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader 
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20,
                backgroundColor: "#030614",
              }}
              inset
              color="inherit">
              {i18n.t("mainDrawer.listItems.administration")}
              
            </ListSubheader>
			
            {showCampaigns && (
              <>
                <ListItem 
                className={classes.listItem}
                  button
                  
                  onClick={(event) => {
                    event.stopPropagation(); 
                    setOpenCampaignSubmenu((prev) => !prev)}}
                    
                    
                  
                >
                  <ListItemIcon className={classes.icon}>
                    <EventAvailableIcon style={{ fontSize: 24 }} className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                    
                    
                  />
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                  
                </ListItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                 
                >
                  <List component="div" disablePadding>
                    <ListItem className={classes.listItem} onClick={() => history.push("/campaigns")} button> 
                      <ListItemIcon className={classes.icon}>
                        <FormatListBulletedIcon style={{ fontSize: 24 }} className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary="Listagem"  />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/contact-lists")}
                      button
                      className={classes.listItem}
                    >
                      <ListItemIcon className={classes.icon}>
                        <PeopleOutlineIcon style={{ fontSize: 24 }} className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary="Listas de Contatos" />
                    </ListItem>
                    <ListItem 
                      onClick={() => history.push("/campaigns-config")}
                      button
                      className={classes.listItem}
                    >
                      <ListItemIcon className={classes.icon}>
                        <SettingsIcon style={{ fontSize: 24 }} className={classes.icon} />
                        
                      </ListItemIcon>
                      
                      <ListItemText primary="Configurações" />
                      
                    </ListItem>
                  </List>
                </Collapse>
                
              </>
            )}
            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<AnnouncementIcon style={{ fontSize: 24 }} className={classes.icon}/>}
                className={classes.listItem}
                TypographyProps={{
                  style: {
                    fontSize: '1rem',
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: 500,
                  },
                }}
                
              />
            )}
            {showOpenAi && (
              <ListItemLink
                to="/prompts"
                primary={i18n.t("mainDrawer.listItems.prompts")}
                icon={<EmojiObjectsIcon style={{ fontSize: 24 }} className={classes.icon}/>}
                className={classes.listItem}
              />
            )}

            {showIntegrations && (
              <ListItemLink
                to="/queue-integration"
                primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                icon={<MergeTypeIcon style={{ fontSize: 24 }} className={classes.icon}/>}
                className={classes.listItem}
              />
            )}
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <ReplayIcon style={{ fontSize: 24 }} className={classes.icon}/>
                </Badge>
              }
              className={classes.listItem}
            />
            {/* <ListItemLink
              to="/files"
              primary={i18n.t("mainDrawer.listItems.files")}
              icon={<InsertDriveFileIcon style={{ fontSize: 24 }} className={classes.icon}/>}
              className={classes.listItem}
            /> */}
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeIcon style={{ fontSize: 24 }} className={classes.icon}/>}
              className={classes.listItem}
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<GroupIcon style={{ fontSize: 24 }} className={classes.icon}/>}
              className={classes.listItem}
            />
            {showExternalApi && (
              <>
                <ListItemLink
                  to="/messages-api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon={<CodeIcon style={{ fontSize: 24 }} className={classes.icon}/>}
                  className={classes.listItem}
                />
              </>
            )}
            <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<AttachMoneyIcon style={{ fontSize: 24 }} className={classes.icon}/>}
              className={classes.listItem}
            />

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsIcon style={{ fontSize: 24 }} className={classes.icon} />}
              className={classes.listItem}
            />
			
            <List>
              {/* <ListItem button component={NavLink} to="/pagamentos">
                <ListItemIcon>
                  <PaymentIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Pagamentos" />
              </ListItem> */}
              <ListItem button component={NavLink} to="/relatorio-mensal">
                <ListItemIcon>
                  <InsertChartIcon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText primary="Relatório Mensal" />
              </ListItem>
            </List>

            {!collapsed && <React.Fragment>
              <Divider />
              {/* 
              // IMAGEM NO MENU
              <Hidden only={['sm', 'xs']}>
                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
              </Hidden> 
              */}
              <Typography style={{ fontSize: "12px", padding: "10px", textAlign: "right", fontWeight: "bold", fontFamily: '"Roboto", sans-serif', }}>
                Versão: 6.0.0
              </Typography>
            </React.Fragment>
            }
			
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;