import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const AddClienteDialog = React.memo(({ open, novoCliente, onSave, onClose }) => {
  const [localNovoCliente, setLocalNovoCliente] = useState(novoCliente || {});

  useEffect(() => {
    if (open) {
      setLocalNovoCliente(novoCliente || {});
    }
  }, [open, novoCliente]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // For numeric fields, you could add formatting if needed
    setLocalNovoCliente(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(localNovoCliente);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-cliente-dialog-title">
      <DialogTitle id="add-cliente-dialog-title">Adicionar Novo Cliente</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="nome"
          label="Nome do Cliente"
          type="text"
          fullWidth
          value={localNovoCliente.nome || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="telefone"
          label="Número de Telefone"
          type="tel"
          fullWidth
          value={localNovoCliente.telefone || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputProps: { inputMode: 'numeric', pattern: '[0-9]*' } }}
        />
        <TextField
          margin="dense"
          name="grupo"
          label="Nome do Grupo"
          type="text"
          fullWidth
          value={localNovoCliente.grupo || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="dataEntrada"
          label="Data de Entrada no Grupo"
          type="date"
          fullWidth
          value={localNovoCliente.dataEntrada || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="dataVencimento"
          label="Data de Vencimento"
          type="date"
          fullWidth
          value={localNovoCliente.dataVencimento || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="idGrupo"
          label="ID do Grupo"
          type="text"
          fullWidth
          value={localNovoCliente.idGrupo || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AddClienteDialog;
