import React from "react";
import { useMediaQuery } from '@mui/material';

import Tickets from "../TicketsCustom";
import TicketAdvanced from "../TicketsAdvanced";

function TicketResponsiveContainer(props) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  if (isDesktop) {
    return <Tickets />;
  }
  return <TicketAdvanced />;
}

export default TicketResponsiveContainer;