import React from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';

// Estilos personalizados usando Material-UI
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  contactName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const VcardPreview = ({ contact, numbers }) => {
  const classes = useStyles();

  // Função para simular o início de uma conversa (pode ser adaptada para sua lógica real)
  const startChat = (number) => {
    alert(`Iniciando conversa com: ${contact} no número: ${number}`);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Contato
        </Typography>
        <Typography className={classes.contactName} variant="h5" component="h2">
          {contact}
        </Typography>
        <Typography variant="body2" component="p">
          Número: {numbers}
        </Typography>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => startChat(numbers)}
          >
            Iniciar conversa
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VcardPreview;
