import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, TextField, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box, Modal, useTheme, useMediaQuery, MenuItem, Select, InputLabel, FormControl, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, CardActions, Divider, Checkbox, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import FilterListIcon from '@mui/icons-material/FilterList';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import api from '../../services/api';
import moment from 'moment-timezone';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { TableSortLabel } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EditClienteDialog from "../../components/EditClienteDialog";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddClienteDialog from '../../components/AddClienteDialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  // Container de botões em formato de grid para melhor aproveitamento de espaço
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down(400)]: {
      gridTemplateColumns: '1fr 1fr', // Force 2 columns for very small screens
      gap: theme.spacing(0.5),
    },
  },
  
  // Estilo base para botões - reduzindo tamanho e tornando-os mais compactos
  buttonBase: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center', // Center the content
    alignItems: 'center',
    padding: theme.spacing(1),
    transition: 'all 0.3s ease',
    whiteSpace: 'nowrap',
    fontSize: '0.9rem',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.6),
      fontSize: '0.7rem',
      minHeight: 32, // Ensure buttons have consistent height
      '& .MuiButton-startIcon': {
        margin: theme.spacing(0, 0.3, 0, 0), // Reduce icon margins
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1rem', // Make icons smaller on mobile
      },
    },
  },

  // Consistent button styling matching the project theme
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(1),
      fontSize: '0.875rem',
    },
    borderRadius: 4,
  },
  
  // Table container with better mobile handling
  tableContainer: {
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vh - 280px)',
    },
  },
  
  // Mobile-optimized search field
  searchField: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  
  // Update pagination styling for consistency
  paginationButton: {
    margin: theme.spacing(0, 0.5),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  
  // Modal styling for better mobile view
  modalContainer: {
    padding: '20px',
    backgroundColor: '#12172F !important',
    color: theme.palette.text.primary,
    borderRadius: '10px',
    width: '90%',
    maxWidth: '400px',
    margin: '10% auto',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      padding: '15px',
      margin: '15% auto',
    },
  },


  // Botão para adicionar (verde)
  addButton: {
    backgroundColor: '#4CAF50',
    '&:hover': {
      backgroundColor: '#388E3C',
    },
  },
  
  // Botão para pagamento (azul)
  paymentButton: {
    backgroundColor: '#2196F3',
    '&:hover': {
      backgroundColor: '#1565C0',
    },
  },
  
  // Botão para filtrar (laranja)
  filterButton: {
    backgroundColor: '#FF9800',
    '&:hover': {
      backgroundColor: '#F57C00',
    },
  },
  
  // Botão para arquivar/desarquivar (roxo)
  archiveButton: {
    backgroundColor: '#9C27B0',
    '&:hover': {
      backgroundColor: '#7B1FA2',
    },
  },
  
  // Botão de agendamento (vermelho)
  scheduleButton: {
    backgroundColor: '#F44336',
    '&:hover': {
      backgroundColor: '#D32F2F',
    },
  },
  // Melhorias para o contêiner da página em dispositivos móveis
  pageContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  
  // Título responsivo
  pageTitle: {
    fontSize: '1.75rem',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '1.35rem',
      textAlign: 'center',
    },
  },
  


  // Tabela otimizada para mobile
  table: {
    minWidth: 650,
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      '& .MuiTableCell-root': {
        padding: theme.spacing(1, 0.5),
        fontSize: '0.75rem',
      },
      '& .MuiTableCell-head': {
        whiteSpace: 'nowrap',
      },
    },
  },
  
  // Ocultar colunas menos importantes em mobile
  hiddenOnMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  
  // Cabeçalho da tabela com melhor contraste
  tableHeader: {
    backgroundColor: '#333',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.75),
      fontSize: '0.7rem',
    },
  },

  // Button text container to handle overflow better
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      maxWidth: '80px', // Limit text width on small screens
    },
  },

  // Estilos para os ícones de ações
  editIcon: {
    color: '#2196F3', // Azul para editar
    '&:hover': {
      color: '#1976D2',
    },
  },
  archiveIcon: {
    color: '#9C27B0', // Roxo para arquivar
    '&:hover': {
      color: '#7B1FA2',
    },
  },
  unarchiveIcon: {
    color: '#4CAF50', // Verde para desarquivar
    '&:hover': {
      color: '#388E3C',
    },
  },
  pdfIcon: {
    color: '#F44336', // Vermelho para PDF
    '&:hover': {
      color: '#D32F2F',
    },
  },
  copyIcon: {
    color: '#FF9800', // Laranja para copiar
    '&:hover': {
      color: '#F57C00',
    },
  },
  
  // Melhoria para o container dos ícones de ação
  actionIconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  },
 
  
  modalTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  
  modalFormControl: {
    margin: theme.spacing(1, 0)
  },
  
  modalActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2)
  },

  
  confirmButton: {
    backgroundColor: theme.palette.primary.main, // Cor primária do tema
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark // Escurece um pouco ao passar o mouse
    }
  },
  
  cancelButton: {
    borderColor: 'rgba(213, 217, 233, 0.5)', // Borda sutil para o botão cancelar
    color: 'rgb(213, 217, 233)',
    '&:hover': {
      borderColor: 'rgb(213, 217, 233)',
      backgroundColor: 'rgba(213, 217, 233, 0.08)'
    }
  },

 
  clientCardRow: {
    marginBottom: theme.spacing(1)
  },
  
  clientCardActions: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1)
  },
  // Ícones com identidade de cores

  // Novo estilo para o menu superior mobile
  mobileTopMenuButton: {
    backgroundColor: theme.palette.primary.light, // cor suave
    color: '#fff',
    padding: theme.spacing(1),
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    textTransform: 'none'
  },
  // Novo estilo para o card otimizado mobile
  clientCard: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#121935',
    borderRadius: '8px',
    border: '1px solid rgba(255,255,255,0.1)',
    paddingTop: theme.spacing(1)
  },
  clientCardContent: {
    padding: theme.spacing(1.5)
  },
  clientCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  clientCardTitle: {
    fontSize: '1.3rem', // aumento do tamanho
    fontWeight: 'bold', // peso mais forte
    color: '#fff',      // cor branca para maior contraste
    cursor: 'pointer'
  },
  cardRow: {
    marginBottom: theme.spacing(1)
  },
  rowIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  },
  cardLabel: {
    fontWeight: 500,
    color: '#ccc'
  },
  cardValue: {
    color: '#fff'
  },
  verMaisButton: {
    marginTop: theme.spacing(1),
    textTransform: 'none'
  },
  // Menu de ações no card (botão “Mais”)
  actionMenuButton: {
    padding: theme.spacing(0.5)
  },
  // Cores específicas para cada ação
  actionEdit: { color: '#2196F3' },
  actionArchive: { color: '#9C27B0' },
  actionUnarchive: { color: '#4CAF50' },
  actionPdf: { color: '#F44336' },
  actionCopy: { color: '#FF9800' }
}));

const ClientManagementPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [allClientes, setAllClientes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [archivedClientes, setArchivedClientes] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [showVencidos, setShowVencidos] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [clienteEditando, setClienteEditando] = useState(null);
  const [busca, setBusca] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [mesesAdicionar, setMesesAdicionar] = useState(1);
  const [metodoPagamento, setMetodoPagamento] = useState('PIX');
  const [creditos, setCreditos] = useState(0); // novo estado para créditos
  const [novoCliente, setNovoCliente] = useState({
    nome: '',
    telefone: '',
    grupo: '',
    dataEntrada: '',
    dataVencimento: '',
    idGrupo: '',
  });
  const [orderBy, setOrderBy] = useState('nome'); // Padrão: ordenar pelo nome
  const [orderDirection, setOrderDirection] = useState('asc'); // Padrão: ordem crescente
  const [isSearching, setIsSearching] = useState(false);
  const clientesRef = useRef(clientes);
  const archivedClientesRef = useRef(archivedClientes);
  const searchTimeoutRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25); // Show 25 rows per page
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [clienteToArchive, setClienteToArchive] = useState(null);
  const [archiveAction, setArchiveAction] = useState(false);



  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await api.get('/api/clientesmanagment');
       
        const ativos = response.data.filter(cliente => !cliente.isArquivado);
        const arquivados = response.data.filter(cliente => cliente.isArquivado);
        setClientes(ativos);
        setAllClientes(response.data);
        setArchivedClientes(arquivados);
      } catch (error) {
        console.error('Erro ao buscar clientes', error);
      }
    };
    fetchClientes();
  }, []);

  useEffect(() => {
    clientesRef.current = clientes;
  }, [clientes]);
  
  useEffect(() => {
    archivedClientesRef.current = archivedClientes;
  }, [archivedClientes]);




  const sortClientes = useCallback((clientes, orderBy, orderDirection) => {
    return clientes.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, []);
 // Filtrar clientes por busca
// Clientes filtrados e ordenados
const filteredClientes = useMemo(() => {
  // If we're actively searching or there's a search term, use the search results
  if (isSearching || busca.trim()) {
    return searchResults;
  }
  
  // Otherwise just show the appropriate list
  const clientesToShow = showArchived ? archivedClientes : clientes;
  
  // Only sort if necessary
  if (orderBy !== 'nome' || orderDirection !== 'asc') {
    return sortClientes([...clientesToShow], orderBy, orderDirection);
  }
  
  return clientesToShow;
}, [busca, isSearching, searchResults, showArchived, archivedClientes, clientes, orderBy, orderDirection, sortClientes]);



 // Função de ordenação
 

const handleRequestSort = (property) => {
  const isAsc = orderBy === property && orderDirection === 'asc';
  setOrderDirection(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};


// Alternar exibição de clientes arquivados
const handleToggleShowArchived = async () => {
  try {
    if (showArchived) {
      // Buscar clientes ativos
      const response = await api.get('/api/clientesmanagment');
      setClientes(response.data);
      setShowArchived(false);
    } else {
      // Buscar clientes arquivados
      const response = await api.get('/api/clientesmanagment/arquivados');
      setArchivedClientes(response.data);
      setShowArchived(true);
    }
  } catch (error) {
    toast.error('Erro ao alternar entre clientes arquivados e ativos');
  }
};


// Arquivar ou desarquivar cliente
const handleArchiveButtonClick = useCallback((cliente, isArquivar) => {
  setClienteToArchive(cliente);
  setArchiveAction(isArquivar);
  setConfirmDialogOpen(true);
}, []);

// Actual archiving function
const handleArchiveCliente = async (cliente, isArquivar) => {
  try {
    setConfirmDialogOpen(false);
    const updatedCliente = { ...cliente, isArquivado: isArquivar };
    await api.put(`/api/clientesmanagment/arquivar/${cliente._id}`, updatedCliente);
    toast.success(isArquivar ? 'Cliente arquivado' : 'Cliente desarquivado');
    const response = await api.get('/api/clientesmanagment');
    const ativos = response.data.filter(cliente => !cliente.isArquivado);
    const arquivados = response.data.filter(cliente => cliente.isArquivado);
    setClientes(ativos);
    setArchivedClientes(arquivados);
  } catch (error) {
    toast.error('Erro ao arquivar cliente');
  }
};

// Cancel archiving
const handleCancelArchive = () => {
  setConfirmDialogOpen(false);
  setClienteToArchive(null);
};

// Confirm archiving
const handleConfirmArchive = () => {
  if (clienteToArchive) {
    handleArchiveCliente(clienteToArchive, archiveAction);
  }
};

// Adicionar ou remover meses de pagamento
const confirmarAdicionarMeses = async () => {
  if (selectedClientes.length !== 1) {
    toast.error('Selecione um cliente para adicionar pagamento.');
    return;
  }

  const cliente = clientes.find(c => c._id === selectedClientes[0]);
  if (!cliente) {
    toast.error('Cliente não encontrado.');
    return;
  }

  try {
    const dataAtualizada = moment.utc(cliente.dataVencimento)
      .add(mesesAdicionar, 'months')
      .format('YYYY-MM-DD');

    // Primeiro: Registrar o pagamento no log
    await api.post('/api/clientesmanagment/pagamento', {
      idCliente: cliente._id,
      mesesAdicionados: mesesAdicionar,
      metodoPagamento,
      credit: creditos, // envia créditos adicionais
      dataAntiga: moment.utc(cliente.dataVencimento).format('YYYY-MM-DD'),
      dataAtual: dataAtualizada,
    });

    // Segundo: Atualizar a data de vencimento do cliente
    const response = await api.put(`/api/clientesmanagment/${cliente._id}`, {
      dataVencimento: dataAtualizada,
    });

    const updatedCliente = response.data;
    setClientes(clientes.map(c => (c._id === updatedCliente._id ? updatedCliente : c)));
    setAllClientes(allClientes.map(c => (c._id === updatedCliente._id ? updatedCliente : c)));
    toast.success('Pagamento adicionado e registrado com sucesso!');
    
    // New actions: Show message, clear selection and reset fields
    toast.info(`${mesesAdicionar} mês(es) adicionados.`);
    setSelectedClientes([]);
    setMesesAdicionar(1);
    setMetodoPagamento('PIX');
    setCreditos(0);
    
    closePagamentoModal();
  } catch (error) {
    toast.error('Erro ao adicionar pagamento');
  }
};

// Função para abrir o modal de pagamento
const openPagamentoModal = () => {
  if (selectedClientes.length !== 1) {
    toast.error('Selecione um cliente para adicionar pagamento.');
    return;
  }
  setOpenModal(true);
};

// Função para fechar o modal de pagamento
const closePagamentoModal = () => {
  setOpenModal(false);
};

// Filtrar clientes vencidos
const handleFilterVencidos = () => {
  if (showVencidos) {
    // Exibir todos os clientes ativos
    setClientes(allClientes.filter(cliente => !cliente.isArquivado));
  } else {
    // Exibir apenas os clientes vencidos
    setClientes(allClientes.filter(cliente => {
      if (!cliente.dataVencimento) return false;

      // Normaliza a data do banco e a data atual para o formato YYYY-MM-DD
      const dataVencimento = moment.utc(cliente.dataVencimento).format("YYYY-MM-DD");
      const dataAtual = moment().tz("America/Sao_Paulo").format("YYYY-MM-DD");

      return dataVencimento < dataAtual; // Considera vencido apenas se for antes de hoje
    }));
  }
  setShowVencidos(prev => !prev);
  setSearchResults([]); // Redefine os resultados para atualizar a contagem
};



// Seleção de clientes
const handleSelectCliente = useCallback((id) => {
  setSelectedClientes((prevSelected) =>
    prevSelected.includes(id)
      ? prevSelected.filter((clienteId) => clienteId !== id)
      : [...prevSelected, id]
  );
}, []);

// Função para agendar pagamento - Versão atualizada para usar apenas clientes selecionados
const handleAgendarPagamento = async () => {
  try {
    // Verificar se há clientes selecionados
    if (selectedClientes.length === 0) {
      toast.info('Selecione pelo menos um cliente para agendar.');
      return;
    }

    // Definindo a data atual no formato 'DDMMYYYY' para o nome da lista
    const currentDate = moment().format('DDMMYYYY');
    const contactListData = { name: currentDate };
    
    // Buscar informações completas dos clientes selecionados
    const clientesSelecionados = clientes.filter(cliente => selectedClientes.includes(cliente._id));
    
    if (clientesSelecionados.length === 0) {
      toast.info('Os clientes selecionados não estão disponíveis.');
      return;
    }
    
    // Criar lista de contatos
    const response = await api.post('/contact-lists', contactListData);
    const contactListId = response.data.id;
  
    // Processar cada cliente selecionado
    for (const cliente of clientesSelecionados) {
      const contactItemData = {
        name: cliente.nome.split(' ')[0], // Primeiro nome
        number: cliente.telefone,
        email: moment.utc(cliente.dataVencimento).format('DD/MM/YYYY'), // Data de vencimento
        companyId: 1, 
        contactListId: contactListId,
      };
      
      // Adicionar cliente à lista de contatos
      await api.post("/contact-list-items", contactItemData);
    }
  
    // Exibir mensagem de sucesso após todas as requisições
    toast.success(`${clientesSelecionados.length} cliente(s) agendado(s) com sucesso!`);
    
    // Limpar todas as seleções após o agendamento bem-sucedido
    setSelectedClientes([]);
    
  } catch (error) {
    console.error(error);
    toast.error('Ocorreu um erro ao agendar os clientes.');
  }
};


// Substituir o método de adicionar cliente e a integração com o AddClienteDialog
const handleAddCliente = () => {
  setAddDialogOpen(true);
};

const handleCloseAddDialog = () => {
  setAddDialogOpen(false);
};

const handleSaveNovoCliente = async (clienteData) => {
  try {
    console.log("Enviando dados do cliente para a API:", clienteData);

    if (!clienteData || !clienteData.nome || !clienteData.telefone || 
        !clienteData.grupo || !clienteData.dataEntrada || 
        !clienteData.dataVencimento || !clienteData.idGrupo) {
      toast.error("Todos os campos são obrigatórios");
      return;
    }

    const response = await api.post('/api/clientesmanagment', clienteData);
    
    setClientes((prevClientes) => [...prevClientes, response.data]);
    setAllClientes((prevAll) => [...prevAll, response.data]);
    setAddDialogOpen(false);
    toast.success('Cliente adicionado com sucesso!');
    
  } catch (error) {
    console.error("Erro ao salvar cliente:", error);
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Erro ao adicionar cliente. Por favor, tente novamente.');
    }
  }
};


const handleEditCliente = useCallback((cliente) => {
  setClienteEditando(cliente);
  setEditDialogOpen(true);
}, []);

const handleSaveEditCliente = async (updatedCliente) => {
  try {
    const response = await api.put(`/api/clientesmanagment/${updatedCliente._id}`, updatedCliente);
    setClientes(prev => prev.map(c => (c._id === response.data._id ? response.data : c)));
    setAllClientes(prev => prev.map(c => (c._id === response.data._id ? response.data : c)));
    setEditDialogOpen(false);
    toast.success("Cliente atualizado com sucesso!");
  } catch (error) {
    toast.error("Erro ao atualizar cliente.");
  }
};

const handleGerarComprovante = useCallback(async (clienteId) => {
  try {
    const comprovanteResponse = await api.get(`/api/clientesmanagment/comprovante/${clienteId}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([comprovanteResponse.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `comprovante_${clienteId}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    toast.error('Erro ao gerar comprovante.');
  }
}, [toast]);

// New handler to copy formatted text
const handleCopyInfo = useCallback((cliente) => {
  const text = `*Grupo*: _${cliente.grupo}_
*Responsavel*: _${cliente.nome}_
*Numero do telefone*: _${cliente.telefone}_`;
  navigator.clipboard.writeText(text);
  toast.success("Copiado para clipboard!");
}, []);

// Memoize table rows for better performance
const tableRows = useMemo(() => {
  return filteredClientes.map((cliente) => {
    const isOverdue = cliente.dataVencimento 
      ? moment.utc(cliente.dataVencimento).tz("America/Sao_Paulo").isSameOrBefore(moment().tz("America/Sao_Paulo"), 'day')
      : false;
    
    return (
      <TableRow key={cliente._id} selected={selectedClientes.includes(cliente._id)}>
        <TableCell>
          <input
            type="checkbox"
            checked={selectedClientes.includes(cliente._id)}
            onChange={() => handleSelectCliente(cliente._id)}
          />
        </TableCell>
        <TableCell
          onClick={() => history.push(`/pagamentos/${cliente.idGrupo}`)}
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          {cliente.nome}
        </TableCell>
        <TableCell>{cliente.telefone}</TableCell>
        <TableCell>{cliente.grupo}</TableCell>
        <TableCell>{moment.utc(cliente.dataEntrada).format('DD/MM/YYYY')}</TableCell>
        <TableCell
          style={{ color: isOverdue ? 'red' : 'inherit' }}
        >
          {cliente.dataVencimento 
            ? `${moment.utc(cliente.dataVencimento).format('DD/MM/YYYY')}${isOverdue ? ' (Vencido)' : ''}`
            : ''}
          {isOverdue && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ marginLeft: '8px', color: 'red' }}
              title="Em Dívida"
            />
          )}
        </TableCell>
        <TableCell>{cliente.idGrupo}</TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <IconButton onClick={() => handleEditCliente(cliente)} size="large">
              <EditIcon className={classes.svgicon} />
            </IconButton>
            <IconButton
              onClick={() => handleArchiveButtonClick(cliente, !cliente.isArquivado)}
              size="large">
              {cliente.isArquivado ? (
                <UnarchiveIcon className={classes.svgicon} />
              ) : (
                <ArchiveIcon className={classes.svgicon} />
              )}
            </IconButton>
            <IconButton onClick={() => handleGerarComprovante(cliente.idGrupo)} size="large">
              <PictureAsPdfIcon className={classes.svgicon} />
            </IconButton>
            <IconButton onClick={() => handleCopyInfo(cliente)} size="large">
              <FileCopyIcon className={classes.svgicon} />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    );
  });
}, [filteredClientes, selectedClientes, handleSelectCliente, history, handleEditCliente, handleArchiveButtonClick, handleGerarComprovante, handleCopyInfo, classes.svgicon]);

// Optimize the search input handler with useCallback
const handleSearchChange = useCallback((e) => {
  const value = e.target.value;
  setBusca(value);
  
  // Show loading indicator
  setIsLoading(true);
  setIsSearching(true);
  
  // Clear any existing timeout
  if (searchTimeoutRef.current) {
    clearTimeout(searchTimeoutRef.current);
  }
  
  // Set a timeout to perform search after typing stops
  searchTimeoutRef.current = setTimeout(() => {
    // If search is empty, just use the full list
    if (!value.trim()) {
      setSearchResults(showArchived ? archivedClientesRef.current : clientesRef.current);
      setIsSearching(false);
      setIsLoading(false);
      return;
    }
    
    const searchTerm = value.toLowerCase().trim();
    const clientesToSearch = showArchived ? archivedClientesRef.current : clientesRef.current;
    
    // Limit results to improve performance
    const results = clientesToSearch.filter(cliente => {
      if (!cliente) return false;
      
      return (cliente.nome && cliente.nome.toLowerCase().includes(searchTerm)) ||
             (cliente.telefone && cliente.telefone.includes(searchTerm)) ||
             (cliente.grupo && cliente.grupo.toLowerCase().includes(searchTerm)) ||
             (cliente.idGrupo && cliente.idGrupo.includes(searchTerm));
    }).slice(0, 200); // Limit to 200 results max
    
    setSearchResults(results);
    setIsSearching(false);
    setIsLoading(false);
    setPage(0); // Reset to first page when search results change
  }, 300);
}, [showArchived]);

// Get current page of clients to display
const displayedClients = useMemo(() => {
  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  return filteredClientes.slice(start, end);
}, [filteredClientes, page, rowsPerPage]);

// Handle page change
const handlePageChange = useCallback((newPage) => {
  setPage(newPage);
}, []);

// Novo component para exibir cada cliente em formato card (mobile)
const ClientCard = useCallback(({ cliente }) => {
  // Use endOf('day') and startOf('day') to ensure today's date is counted as overdue.
  const isOverdue = cliente.dataVencimento 
    ? moment(cliente.dataVencimento).endOf('day').isBefore(moment().startOf('day'))
    : false;
  
  // Apply a light red background for overdue clients.
  const cardStyle = isOverdue ? { backgroundColor: '#ffcccc' } : {};

  return (
    <Card className={classes.clientCard} style={cardStyle}>
      <CardContent className={classes.clientCardContent}>
        {/* Cabeçalho com ícone para nome */}
        <Box className={classes.clientCardHeader}>
          <AccountCircleIcon fontSize="small" style={{ marginRight: 4 }}/>
          <Typography
            variant="h6"
            onClick={() => history.push(`/pagamentos/${cliente.idGrupo}`)}
            className={classes.clientCardTitle}
          >
            {cliente.nome}
          </Typography>
          <Checkbox 
            checked={selectedClientes.includes(cliente._id)}
            onChange={() => handleSelectCliente(cliente._id)}
            color="primary"
          />
        </Box>
        {/* Campo Grupo com ícone */}
        <Grid container spacing={1} className={classes.cardRow}>
          <Grid item xs={6}>
            <Typography className={classes.cardLabel}>
              <GroupIcon fontSize="small" className={classes.rowIcon}/>Grupo:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.cardValue}>{cliente.grupo}</Typography>
          </Grid>
        </Grid>
        {/* Campo Telefone (já com ícone) */}
        <Grid container spacing={1} className={classes.cardRow}>
          <Grid item xs={6}>
            <Typography className={classes.cardLabel}>
              <PhoneIcon fontSize="small" className={classes.rowIcon}/>Telefone:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.cardValue}>{cliente.telefone}</Typography>
          </Grid>
        </Grid>
        {/* Campo Vencimento (já com ícone) */}
        <Grid container spacing={1} className={classes.cardRow}>
          <Grid item xs={6}>
            <Typography className={classes.cardLabel}>
              <CalendarTodayIcon fontSize="small" className={classes.rowIcon}/>Vencimento:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.cardValue} style={{ color: isOverdue ? 'red' : '#fff' }}>
              {cliente.dataVencimento ? moment(cliente.dataVencimento).format('DD/MM/YYYY') : ''}
          
            </Typography>
            {isOverdue && (
              <ErrorOutlineIcon fontSize="small" style={{ color: 'red', marginLeft: 4 }}/>
            )}
          </Grid>
        </Grid>
        {/* Campo ID com ícone */}
        <Grid container spacing={1} className={classes.cardRow}>
          <Grid item xs={6}>
            <Typography className={classes.cardLabel}>
              <FingerprintIcon fontSize="small" className={classes.rowIcon}/>ID:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.cardValue}>{cliente.idGrupo}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.clientCardActions}>
        <IconButton onClick={() => handleEditCliente(cliente)} title="Editar" size="large">
          <EditIcon className={classes.editIcon}/>
        </IconButton>
        <IconButton
          onClick={() => handleArchiveButtonClick(cliente, !cliente.isArquivado)}
          title={cliente.isArquivado ? "Desarquivar" : "Arquivar"}
          size="large">
          {cliente.isArquivado ? <UnarchiveIcon className={classes.unarchiveIcon}/> : <ArchiveIcon className={classes.archiveIcon}/>}
        </IconButton>
        <IconButton
          onClick={() => handleGerarComprovante(cliente.idGrupo)}
          title="Gerar Comprovante PDF"
          size="large">
          <PictureAsPdfIcon className={classes.pdfIcon}/>
        </IconButton>
        <IconButton onClick={() => handleCopyInfo(cliente)} title="Copiar" size="large">
          <FileCopyIcon className={classes.copyIcon}/>
        </IconButton>
      </CardActions>
    </Card>
  );
}, [classes, history, selectedClientes, handleSelectCliente, handleEditCliente, handleArchiveButtonClick, handleGerarComprovante, handleCopyInfo]);

// Estado e handlers para o menu superior mobile
const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
const handleMobileMenuOpen = (e) => setMobileMenuAnchor(e.currentTarget);
const handleMobileMenuClose = () => setMobileMenuAnchor(null);

return (
  <Container maxWidth="lg" className={classes.pageContainer} disableGutters={isMobile}>
    <Typography variant="h4" className={classes.pageTitle}>
      Gerenciar Clientes
    </Typography>
    <TextField
      label="Buscar Cliente"
      variant="outlined"
      fullWidth
      value={busca}
      onChange={handleSearchChange}
      className={classes.searchField}
      placeholder="Nome, telefone ou grupo..."
      InputProps={{
        autoComplete: 'off',
        startAdornment: (
          <Box display="flex" alignItems="center" mr={1}>
            {isLoading ? <CircularProgress size={20} /> : null}
          </Box>
        ),
      }}
    />
    {/* Desktop action buttons - increased spacing and using "outlined" variant for a softer look */}
    {!isMobile && (
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
        style={{ gap: theme.spacing(3) }} // Increase spacing between buttons
      >
        <Button variant="outlined" className={classes.addButton} onClick={handleAddCliente} startIcon={<AddIcon />}>
          Novo Cliente
        </Button>
        <Button variant="outlined" className={classes.scheduleButton} onClick={handleAgendarPagamento} startIcon={<AlarmAddIcon />}>
          Agendar
        </Button>
        <Button variant="outlined" className={classes.paymentButton} onClick={openPagamentoModal} startIcon={<PaymentIcon />}>
          Pagar
        </Button>
        <Button variant="outlined" className={classes.filterButton} onClick={handleFilterVencidos} startIcon={<FilterListIcon />}>
        {showVencidos ? "Ver Todos" : "Vencidos"}
          
        </Button>
        <Button variant="outlined" className={classes.archiveButton} onClick={handleToggleShowArchived}>
          {showArchived ? "Ver Ativos" : "Ver Arquivados"}
        </Button>
      </Box>
    )}
    {isMobile ? (
      <>
        {/* Updated mobile actions container using a grid with an added sort button */}
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2} mb={2}>
          <Button variant="outlined" className={classes.addButton} onClick={handleAddCliente} startIcon={<AddIcon />}>
             Novo Cliente
          </Button>
          <Button variant="outlined" className={classes.scheduleButton} onClick={handleAgendarPagamento} startIcon={<AlarmAddIcon />}>
             Agendar
          </Button>
          <Button variant="outlined" className={classes.paymentButton} onClick={openPagamentoModal} startIcon={<PaymentIcon />}>
             Pagar
          </Button>
          <Button variant="outlined" className={classes.filterButton} onClick={handleFilterVencidos} startIcon={<FilterListIcon />}>
             Filtrar Vencidos
          </Button>
          <Button variant="outlined" className={classes.archiveButton} onClick={handleToggleShowArchived}>
             {showArchived ? "Ver Ativos" : "Ver Arquivados"}
          </Button>
          <Button variant="outlined" className={classes.actionButton} onClick={() => handleRequestSort('dataVencimento')} startIcon={<CalendarTodayIcon />}>
             Ordenar por Vencimento
          </Button>
        </Box>
        {/* Nova seção para exibir informações de paginação */}
        <Box mt={2}>
          <Typography variant="body2" align="center">
            Página {page + 1} de {Math.ceil(filteredClientes.length / rowsPerPage) || 1} - Total de clientes: {filteredClientes.length}
          </Typography>
        </Box>
        <Box mt={2}>
          {displayedClients.length === 0 ? (
            <Paper style={{ padding: 16, textAlign: 'center' }}>
              {isLoading ? 'Carregando...' : 'Nenhum cliente encontrado'}
            </Paper>
          ) : (
            displayedClients.map(cliente => (
              <ClientCard key={cliente._id} cliente={cliente} />
            ))
          )}
        </Box>
      </>
    ) : (
      // ...existing desktop table layout...
      (<TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="Tabela de Clientes" className={classes.table} size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} padding="checkbox">
                {/* Checkbox header */}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'nome'}
                  direction={orderBy === 'nome' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('nome')}
                >
                  Nome do Cliente
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeader}>
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'telefone'}
                  direction={orderBy === 'telefone' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('telefone')}
                >
                  Número de Telefone
                </TableSortLabel>
              </TableCell>
              <TableCell className={`${classes.tableHeader} ${classes.hiddenOnMobile}`}>
                {/* Grupo - hidden on mobile */}
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'grupo'}
                  direction={orderBy === 'grupo' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('grupo')}
                >
                  Grupo
                </TableSortLabel>
              </TableCell>
              <TableCell className={`${classes.tableHeader} ${classes.hiddenOnMobile}`}>
                {/* Data de Entrada - hidden on mobile */}
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'dataEntrada'}
                  direction={orderBy === 'dataEntrada' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('dataEntrada')}
                >
                  Entrada
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeader}>
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'dataVencimento'}
                  direction={orderBy === 'dataVencimento' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('dataVencimento')}
                >
                  Data de Vencimento
                </TableSortLabel>
              </TableCell>
              <TableCell className={`${classes.tableHeader} ${classes.hiddenOnMobile}`}>
                {/* ID do Grupo - hidden on mobile */}
                <TableSortLabel
                  style={{ color: 'white' }}
                  active={orderBy === 'idGrupo'}
                  direction={orderBy === 'idGrupo' ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort('idGrupo')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHeader} padding={isMobile ? "none" : "default"}>
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedClients.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {isLoading ? 'Carregando...' : 'Nenhum cliente encontrado'}
                </TableCell>
              </TableRow>
            ) : (
              displayedClients.map((cliente) => {
                const isOverdue = cliente.dataVencimento 
                  ? moment.utc(cliente.dataVencimento).tz("America/Sao_Paulo").isSameOrBefore(moment().tz("America/Sao_Paulo"), 'day')
                  : false;

                return (
                  <TableRow key={cliente._id} selected={selectedClientes.includes(cliente._id)}>
                    <TableCell padding="checkbox">
                      <input
                        type="checkbox"
                        checked={selectedClientes.includes(cliente._id)}
                        onChange={() => handleSelectCliente(cliente._id)}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => history.push(`/pagamentos/${cliente.idGrupo}`)}
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                      {cliente.nome}
                    </TableCell>
                    <TableCell>{cliente.telefone}</TableCell>
                    <TableCell className={classes.hiddenOnMobile}>{cliente.grupo}</TableCell>
                    <TableCell className={classes.hiddenOnMobile}>
                      {cliente.dataEntrada ? moment.utc(cliente.dataEntrada).format('DD/MM/YY') : ''}
                    </TableCell>
                    <TableCell style={{ color: isOverdue ? 'red' : 'inherit' }}>
                      {cliente.dataVencimento 
                        ? `${moment.utc(cliente.dataVencimento).format('DD/MM/YY')}${isOverdue ? ' (Vencido)' : ''}`
                        : ''}
                      {isOverdue && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          style={{ marginLeft: '4px', color: 'red' }}
                          title="Em Dívida"
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.hiddenOnMobile}>{cliente.idGrupo}</TableCell>
                    <TableCell padding={isMobile ? "none" : "default"}>
                      <div className={classes.actionIconsContainer}>
                        <IconButton 
                          onClick={() => handleEditCliente(cliente)}
                          size={isMobile ? "small" : "medium"}
                          title="Editar"
                        >
                          <EditIcon className={classes.editIcon} fontSize={isMobile ? "small" : "default"} />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleArchiveButtonClick(cliente, !cliente.isArquivado)}
                          size={isMobile ? "small" : "medium"}
                          title={cliente.isArquivado ? "Desarquivar" : "Arquivar"}
                        >
                          {cliente.isArquivado ? (
                            <UnarchiveIcon className={classes.unarchiveIcon} fontSize={isMobile ? "small" : "default"} />
                          ) : (
                            <ArchiveIcon className={classes.archiveIcon} fontSize={isMobile ? "small" : "default"} />
                          )}
                        </IconButton>
                        <IconButton 
                          onClick={() => handleGerarComprovante(cliente.idGrupo)}
                          size={isMobile ? "small" : "medium"}
                          title="Gerar Comprovante PDF"
                        >
                          <PictureAsPdfIcon className={classes.pdfIcon} fontSize={isMobile ? "small" : "default"} />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleCopyInfo(cliente)}
                          size={isMobile ? "small" : "medium"}
                          title="Copiar Informações"
                        >
                          <FileCopyIcon className={classes.copyIcon} fontSize={isMobile ? "small" : "default"} />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>)
    )}
    {/* Bottom pagination buttons - Updated with styles */}
    <Box 
      display="flex" 
      justifyContent="center" 
      className={classes.paginationContainer}
      flexDirection={isMobile ? "column" : "row"}
      alignItems="center"
      gap={isMobile ? 1 : 0}
    >
      {!isMobile ? (
        // Desktop pagination controls
        (<Box display="flex" alignItems="center">
          <Button 
            disabled={page === 0} 
            onClick={() => handlePageChange(0)} 
            size="small"
            className={classes.paginationButton}
            variant="contained"
          >
            Primeira
          </Button>
          <Button 
            disabled={page === 0} 
            onClick={() => handlePageChange(page - 1)} 
            size="small"
            className={classes.paginationButton}
            variant="contained"
          >
            Anterior
          </Button>
          <Typography variant="body2" style={{ margin: '0 16px' }}>
            Página {page + 1} de {Math.ceil(filteredClientes.length / rowsPerPage) || 1}
          </Typography>
          <Typography variant="body2" style={{ marginRight: 16 }}>
            Total de clientes: {filteredClientes.length}
          </Typography>
          <Button 
            disabled={(page + 1) * rowsPerPage >= filteredClientes.length} 
            onClick={() => handlePageChange(page + 1)} 
            size="small"
            className={classes.paginationButton}
            variant="contained"
          >
            Próximo
          </Button>
          <Button 
            disabled={(page + 1) * rowsPerPage >= filteredClientes.length} 
            onClick={() => handlePageChange(Math.max(0, Math.ceil(filteredClientes.length / rowsPerPage) - 1))} 
            size="small"
            className={classes.paginationButton}
            variant="contained"
          >
            Última
          </Button>
        </Box>)
      ) : (
        // Mobile pagination controls - simplified
        (<>
          <Typography variant="body2" align="center" style={{ marginBottom: 8 }}>
            Página {page + 1} de {Math.ceil(filteredClientes.length / rowsPerPage) || 1}
          </Typography>
          <Box display="flex" justifyContent="center" gap={1}>
            <Button 
              disabled={page === 0} 
              onClick={() => handlePageChange(page - 1)} 
              size="small"
              className={classes.paginationButton}
              variant="contained"
              startIcon={<NavigateBeforeIcon />}
            >
              Anterior
            </Button>
            <Button 
              disabled={(page + 1) * rowsPerPage >= filteredClientes.length} 
              onClick={() => handlePageChange(page + 1)} 
              size="small"
              className={classes.paginationButton}
              variant="contained"
              endIcon={<NavigateNextIcon />}
            >
              Próximo
            </Button>
          </Box>
        </>)
      )}
    </Box>
    {/* Modal para adicionar pagamento */}
    <Modal open={openModal} onClose={closePagamentoModal}>
      <div className={classes.modalContainer}>
        <Typography variant="h6" className={classes.modalTitle}>
          Adicionar Pagamento
        </Typography>

        <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
          <InputLabel id="meses-adicionar-label">Meses</InputLabel>
          <Select
            labelId="meses-adicionar-label"
            value={mesesAdicionar}
            onChange={(e) => setMesesAdicionar(parseInt(e.target.value))}
            label="Meses"
          >
            {[...Array(24)].map((_, i) => (
              <MenuItem key={i+1} value={i+1}>{i+1}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Créditos adicionais"
          type="number"
          value={creditos}
          onChange={(e) => setCreditos(parseInt(e.target.value || 0))}
          fullWidth
          className={classes.modalFormControl}
          variant="outlined"
        />

        <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
          <InputLabel id="metodo-pagamento-label">Método de Pagamento</InputLabel>
          <Select
            labelId="metodo-pagamento-label"
            value={metodoPagamento}
            onChange={(e) => setMetodoPagamento(e.target.value)}
            label="Método de Pagamento"
          >
            <MenuItem value="PIX">PIX</MenuItem>
            <MenuItem value="Transferência Bancária">Transferência</MenuItem>
            <MenuItem value="PayPal">PayPal</MenuItem>
            <MenuItem value="Wise">Wise</MenuItem>
          </Select>
        </FormControl>

        <div className={classes.modalActions}>
          <Button 
            variant="contained" 
            className={classes.confirmButton}
            onClick={confirmarAdicionarMeses}
            startIcon={<PaymentIcon />}
            fullWidth
          >
            Confirmar
          </Button>
          <Button 
            variant="outlined"
            onClick={closePagamentoModal}
            className={classes.cancelButton}
            fullWidth
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
    {/* Diálogo para adicionar cliente - Versão simplificada */}
    <AddClienteDialog
      open={addDialogOpen}
      onSave={handleSaveNovoCliente}
      onClose={handleCloseAddDialog}
    />
    {/* Substitua o Dialog de edição pelo componente memoizado */}
    <EditClienteDialog
      open={editDialogOpen}
      cliente={clienteEditando}
      onSave={handleSaveEditCliente}
      onClose={() => setEditDialogOpen(false)}
    />
    {/* Add confirmation dialog for archive/unarchive */}
    <Dialog
      open={confirmDialogOpen}
      onClose={handleCancelArchive}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {archiveAction ? "Arquivar Cliente" : "Desarquivar Cliente"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {archiveAction 
            ? `Deseja realmente arquivar o cliente "${clienteToArchive?.nome}"?`
            : `Deseja realmente desarquivar o cliente "${clienteToArchive?.nome}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelArchive} color="primary">
          Cancelar
        </Button>
        <Button 
          onClick={handleConfirmArchive} 
          color="primary" 
          variant="contained" 
          autoFocus
          className={classes.paginationButton}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  </Container>
);
};

export default React.memo(ClientManagementPage);
