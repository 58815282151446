import React, { useContext, useState, Component } from "react";
import { useHistory } from "react-router-dom";

import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { IconButton } from "@mui/material";
import { MoreVert, Replay } from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import Tooltip from '@mui/material/Tooltip';
import { green } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import ContactDrawer from "../ContactDrawer";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const berryColors = {
	primary: "#606d88",
	secondary: "#ec407a",
	success: "#17c13e",
	warning: "#ffab91",
	whatsapp: "#25D366",
	grey: {
		100: "#f8f9fa",
		200: "#e9ecef",
		300: "#dee2e6",
		400: "#ced4da",
		500: "#adb5bd",
		600: "#6c757d",
		700: "#495057",
		800: "#343a40",
		900: "#212529",
	}
};



const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);

	const customTheme = createTheme({
		palette: {
			primary: { main: berryColors.primary },
			secondary: { main: berryColors.secondary },
			success: { main: berryColors.success },
			warning: { main: berryColors.warning },
		}
	});

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				useIntegration: status === "closed" ? false : ticket.useIntegration,
				promptId: status === "closed" ? false : ticket.promptId,
				integrationId: status === "closed" ? false : ticket.integrationId
			});

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null })
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>
                <div className={classes.actionButtons}>
                    {ticket.status === "closed" && (
                        <ButtonWithSpinner
                            loading={loading}
                            startIcon={<Replay />}
                            size="small"
                            onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
                        >
                            {i18n.t("messagesList.header.buttons.reopen")}
                        </ButtonWithSpinner>
                    )}
                    {ticket.status === "open" && (
                        <>
                            <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
                                <IconButton
                                    onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                                    style={{ color: berryColors.warning }}
                                    size="large">
                                    <UndoRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
                                <IconButton
                                    onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
                                    color="primary"
                                    size="large">
                                    <CheckCircleIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Informações">
                                <IconButton style={{ color: "#d54c48" }} size="large">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={handleOpenTicketOptionsMenu} color="secondary" size="large">
                                <MoreVert />
                            </IconButton>
                            <TicketOptionsMenu
                                ticket={ticket}
                                anchorEl={anchorEl}
                                menuOpen={ticketOptionsMenuOpen}
                                handleClose={handleCloseTicketOptionsMenu}
                            />
                        </>
                    )}
                    {ticket.status === "pending" && (
                        <ButtonWithSpinner
                            loading={loading}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
                        >
                            {i18n.t("messagesList.header.buttons.accept")}
                        </ButtonWithSpinner>
                    )}
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default TicketActionButtonsCustom;
