import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid"; 
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import Container from "@mui/material/Container";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logo.png";


const Copyright = () => {
	return (
		<Typography variant="body2" color="primary" align="center">
			{"Copyright "}
 			<Link color="primary" href="#">
 				PLW
 			</Link>{" "}
 			{new Date().getFullYear()}
 			{"."}
 		</Typography>
 	);
 };

const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		height: "100vh",
		background: "#030614", //cor de fundo 
		//backgroundImage: "url(https://#)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100% 100%",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	paper: {
		backgroundColor: theme.palette.login, //DARK MODE PLW DESIGN//
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "55px 30px",
		borderRadius: "12.5px",
	},
	avatar: {
		margin: theme.spacing(1),  
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	powered: {
		color: "white"
	},
	campoInput: {
		backgroundColor: 'rgb(10, 15, 35)', // Define a cor de fundo do TextField
		'& .MuiInputLabel-root': {
		  color: 'rgb(213, 217, 233)', // Define a cor do label como branco
		},
		'& .MuiOutlinedInput-root': {
		  '& fieldset': {
			borderColor: 'rgb(213, 217, 233)', // Define a cor da borda (opcional)
		  },
		  '&:hover fieldset': {
			borderColor: 'rgb(213, 217, 233)', // Define a cor da borda quando houver hover (opcional)
		  },
		  '&.Mui-focused fieldset': {
			borderColor: 'rgb(213, 217, 233)', // Define a cor da borda quando o campo está focado (opcional)
		  },
		},
		'& .MuiInputBase-input': {
		  color: 'rgb(213, 217, 233)', // Define a cor do texto inserido como branco
		},
		
    '& .MuiOutlinedInput-root .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px rgb(10, 15, 35) inset', // Mantém a cor de fundo
      WebkitTextFillColor: 'white', // Cor do texto preenchido automaticamente
      transition: 'background-color 5000s ease-in-out 0s',
    },
	  },
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

	return (
		<div className={classes.root}>
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<div>
					<center><img style={{ margin: "0 auto", width: "70%" }} src={logo} alt="Logologin" /></center>
				</div>
				{/*<Typography component="h1" variant="h5">
					{i18n.t("login.title")}
				</Typography>*/}
				<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
						className={classes.campoInput}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
						className={classes.campoInput}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					{ <Grid container>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								component={RouterLink}
								to="/signup"
							>
								{i18n.t("login.buttons.register")}
							</Link>
						</Grid>
					</Grid> }
				</form>
			
			</div>
			
			
		</Container>
		</div>
	);
};

export default Login;
