import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button
} from "@mui/material";

const EditClienteDialog = React.memo(({ 
  open, 
  cliente, 
  onSave, 
  onClose 
}) => {
  const [localCliente, setLocalCliente] = useState(cliente || {});

  useEffect(() => {
    setLocalCliente(cliente || {});
  }, [cliente]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Se for telefone, remove caracteres não numéricos
    const newValue = name === "telefone" ? value.replace(/\D/g, "") : value;
    setLocalCliente(prev => ({ ...prev, [name]: newValue }));
  };

  const handleSave = () => {
    onSave(localCliente);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-client-dialog-title">
      <DialogTitle id="edit-client-dialog-title">Editar Cliente</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="nome"
          label="Nome do Cliente"
          type="text"
          fullWidth
          value={localCliente.nome || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="telefone"
          label="Número de Telefone"
          type="tel"
          fullWidth
          value={localCliente.telefone || ""}
          onChange={handleChange}
          InputProps={{ inputProps: { inputMode: "numeric", pattern: "[0-9]*" } }}
        />
        <TextField
          margin="dense"
          name="grupo"
          label="Nome do Grupo"
          type="text"
          fullWidth
          value={localCliente.grupo || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="dataEntrada"
          label="Data de Entrada no Grupo"
          type="date"
          fullWidth
          value={localCliente.dataEntrada || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="dataVencimento"
          label="Data de Vencimento"
          type="date"
          fullWidth
          value={localCliente.dataVencimento || ""}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          name="idGrupo"
          label="ID do Grupo"
          type="text"
          fullWidth
          value={localCliente.idGrupo || ""}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSave} color="primary">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
});

export default EditClienteDialog;
