import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// Atualize o caminho abaixo para que fique dentro de src/
import api from '../../services/api';
import { useParams } from 'react-router-dom';

const PaymentListPage = () => {
  const { idGrupo } = useParams(); // espera que o idGrupo seja passado na rota
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (idGrupo) {
      const fetchPayments = async () => {
        try {
          const response = await api.get(`/api/clientesmanagment/pagamentos/${idGrupo}`);
          setPayments(response.data);
        } catch (error) {
          console.error('Erro ao buscar pagamentos', error);
        }
      };
      fetchPayments();
    }
  }, [idGrupo]);

  if (!idGrupo) {
    return (
      <Container>
        <Typography variant="h6">
          Nenhum grupo selecionado. Selecione um cliente na página de Gerenciamento para visualizar os pagamentos.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Histórico de Pagamentos
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Data do Pagamento</TableCell>
              <TableCell align="center">Método</TableCell>
              <TableCell align="center">Meses Adicionados</TableCell>
              <TableCell align="center">Créditos</TableCell>
              {/* ...outras colunas necessárias... */}
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((p) => (
              <TableRow key={p._id}>
                <TableCell align="center">{new Date(p.dataPagamento).toLocaleDateString()}</TableCell>
                <TableCell align="center">{p.metodoPagamento}</TableCell>
                <TableCell align="center">{p.mesesAdicionados}</TableCell>
                <TableCell align="center">{p.credit}</TableCell>
                {/* ...outras colunas se necessário... */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* ...existing code... */}
    </Container>
  );
};

export default PaymentListPage;
