import React, { useState, useContext, useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, ListItem, ListItemText, InputAdornment, FormControl, InputLabel, Select, Grid, Checkbox, ListItemIcon, Chip } from "@mui/material";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageContext } from "../../context/EditingMessage/EditingMessageContext";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import MessageHistoryModal from "../MessageHistoryModal";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const editingContext = useContext(EditMessageContext);
	const setEditingMessage = editingContext ? editingContext.setEditingMessage : null;
	const [messageHistoryOpen, setMessageHistoryOpen] = useState(false);
	const [forwardDialogOpen, setForwardDialogOpen] = useState(false);
	const [clients, setClients] = useState([]);
	const [filteredClients, setFilteredClients] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedClients, setSelectedClients] = useState([]);
	const [additionalInfo, setAdditionalInfo] = useState("");
	const [months, setMonths] = useState(1);
	
	// Fetch clients when forward dialog opens
	useEffect(() => {
		if (forwardDialogOpen) {
			fetchClients();
			// Reset form values when dialog opens
			setAdditionalInfo("");
			setMonths(1);
			setSelectedClients([]);
		}
	}, [forwardDialogOpen]);

	const fetchClients = async () => {
		try {
			const { data } = await api.get("/api/clientesmanagment");
			// Filter only non-archived clients
			const activeClients = data.filter(client => !client.isArquivado);
			setClients(activeClients);
			setFilteredClients(activeClients);
		} catch (err) {
			toastError(err);
		}
	};

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${message.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleEditMessage = async () => {
		setEditingMessage(message);
		handleClose();
	}

	const handleOpenMessageHistoryModal = (e) => {
		setMessageHistoryOpen(true);
		handleClose();
	}

	const hanldeReplyMessage = () => {
		setReplyingMessage(message);
		handleClose();
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	}; 

	const handleOpenForwardDialog = () => {
		setForwardDialogOpen(true);
		handleClose();
	};

	const handleSearch = (e) => {
		const term = e.target.value.toLowerCase();
		setSearchTerm(term);
		
		const filtered = clients.filter(client => 
			client.nome?.toLowerCase().includes(term) || 
			client.telefone?.toLowerCase().includes(term) || 
			client.grupo?.toLowerCase().includes(term) || 
			client.idGrupo?.toLowerCase().includes(term)
		);
		
		setFilteredClients(filtered);
	};

	const handleToggleClient = (client) => {
		// Check if client is already selected
		const clientIndex = selectedClients.findIndex(c => c.idGrupo === client.idGrupo);
		
		if (clientIndex === -1) {
			// Add to selected clients
			setSelectedClients([...selectedClients, client]);
		} else {
			// Remove from selected clients
			const newSelectedClients = [...selectedClients];
			newSelectedClients.splice(clientIndex, 1);
			setSelectedClients(newSelectedClients);
		}
	};

	const handleForwardMessage = async () => {
		try {
			if (selectedClients.length === 0) {
				toast.error("Selecione pelo menos um grupo para encaminhar");
				return;
			}
			
			const payload = {
				messageId: message.id,
				clients: selectedClients.map(client => ({
					idGrupo: client.idGrupo,
					nome: client.nome,
					telefone: client.telefone,
					grupo: client.grupo
				})),
				additionalInfo: additionalInfo.trim(),
				months: parseInt(months, 10)
			};
			
			const response = await api.post("/messages-payment/forward", payload);
			setForwardDialogOpen(false);
			setSelectedClients([]);
			setAdditionalInfo("");
			setMonths(1);
			toast.success(response.data.message);
		} catch (err) {
			toastError(err);
		}
	};

	// Format date to display in the list
	const formatDate = (date) => {
		if (!date) return "";
		try {
			return format(new Date(date), "dd/MM/yyyy");
		} catch (error) {
			return "";
		}
	};

	const handleAdditionalInfoChange = (e) => {
		setAdditionalInfo(e.target.value);
	};

	const handleMonthsChange = (e) => {
		setMonths(e.target.value);
	};

	const removeSelectedClient = (clientId) => {
		setSelectedClients(selectedClients.filter(client => client.idGrupo !== clientId));
	};

	return (
		<>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<MessageHistoryModal
                open={messageHistoryOpen}
                onClose={setMessageHistoryOpen}
                oldMessages={message.oldMessages}
            >
            </MessageHistoryModal>
			
			{/* Forward Payment Dialog */}
			<Dialog 
				open={forwardDialogOpen} 
				onClose={() => setForwardDialogOpen(false)}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>{i18n.t("messageOptionsMenu.forwardPayment")}</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								autoFocus
								margin="dense"
								label={i18n.t("messageOptionsMenu.searchGroups")}
								fullWidth
								variant="outlined"
								value={searchTerm}
								onChange={handleSearch}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						
						{selectedClients.length > 0 && (
							<Grid item xs={12}>
								<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '8px', marginBottom: '8px' }}>
									{selectedClients.map((client) => (
										<Chip
											key={client.idGrupo}
											label={client.grupo}
											onDelete={() => removeSelectedClient(client.idGrupo)}
											color="primary"
											variant="outlined"
										/>
									))}
								</div>
							</Grid>
						)}
						
						<Grid item xs={12}>
							<List style={{ maxHeight: '300px', overflow: 'auto', border: '1px solid #ddd', borderRadius: '4px' }}>
								{filteredClients.map(client => {
									const isSelected = selectedClients.some(c => c.idGrupo === client.idGrupo);
									
									return (
										<ListItem 
											button 
											key={client.idGrupo} 
											onClick={() => handleToggleClient(client)}
											selected={isSelected}
										>
											<ListItemIcon>
												<Checkbox
													edge="start"
													checked={isSelected}
													tabIndex={-1}
													disableRipple
												/>
											</ListItemIcon>
											<ListItemText 
												primary={client.nome || `Cliente ${client.idGrupo}`} 
												secondary={
													<>
														{client.telefone} | {client.grupo}<br/>
														{i18n.t("messageOptionsMenu.clientSince")}: {formatDate(client.dataEntrada)}<br/>
														{i18n.t("messageOptionsMenu.expiresOn")}: {formatDate(client.dataVencimento)}
													</>
												}
											/>
										</ListItem>
									);
								})}
								{filteredClients.length === 0 && (
									<ListItem>
										<ListItemText primary={i18n.t("messageOptionsMenu.noGroupsFound")} />
									</ListItem>
								)}
							</List>
						</Grid>
						
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="dense">
								<InputLabel id="months-select-label">{i18n.t("messageOptionsMenu.months")}</InputLabel>
								<Select
									labelId="months-select-label"
									id="months-select"
									value={months}
									onChange={handleMonthsChange}
									label={i18n.t("messageOptionsMenu.months")}
								>
									{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (
										<MenuItem key={m} value={m}>{m}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						
						<Grid item xs={12}>
							<TextField
								margin="dense"
								label={i18n.t("messageOptionsMenu.additionalInfo")}
								fullWidth
								multiline
								rows={3}
								variant="outlined"
								value={additionalInfo}
								onChange={handleAdditionalInfoChange}
								placeholder={i18n.t("messageOptionsMenu.additionalInfoPlaceholder")}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setForwardDialogOpen(false)} color="secondary">
						{i18n.t("messageOptionsMenu.cancel")}
					</Button>
					<Button 
						onClick={handleForwardMessage} 
						color="primary" 
						disabled={selectedClients.length === 0}
					>
						{i18n.t("messageOptionsMenu.forward")}
					</Button>
				</DialogActions>
			</Dialog>
			
			<Menu
				anchorEl={anchorEl}
				disablePortal
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{message.fromMe && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("messageOptionsMenu.delete")}
					</MenuItem>
					
				)}
				<MenuItem onClick={hanldeReplyMessage}>
					{i18n.t("messageOptionsMenu.reply")}
				</MenuItem>

				{message.fromMe && (<MenuItem onClick={handleEditMessage}>
					{i18n.t("messageOptionsMenu.edit")}
				</MenuItem>)}
				{message.fromMe && message.oldMessages?.length > 0 && (
				<MenuItem key="history" onClick={handleOpenMessageHistoryModal}>
	                    {i18n.t("messageOptionsMenu.history")}
							
				</MenuItem>)}
				
				{!message.fromMe && (
					<MenuItem onClick={handleOpenForwardDialog}>
						{i18n.t("messageOptionsMenu.forwardPayment")}
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

export default MessageOptionsMenu;
