import React, { useState, useEffect, useReducer, useRef } from "react";


import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@mui/material/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Box,
  Avatar,
  Typography,
  Tooltip,
} from "@mui/material";

import { makeStyles } from '@mui/styles';

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  PlayArrow, Pause, Speed 
} from "@mui/icons-material";

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import LocationPreview from "../LocationPreview";
import VcardPreview from "../VcardPreview";

import whatsBackgroundDark from "../../assets/wa-background-dark.png"; //DARK MODE PLW DESIGN//

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200,
  },

  messagesList: {
    backgroundImage: theme.mode === 'light' ? `url(${whatsBackgroundDark})` : `url(${whatsBackgroundDark})`, //DARK MODE PLW DESIGN//
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  audioContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    padding: '8px',
    width: '280px',
    maxWidth: '100%',
    position: 'relative',
    margin: '8px 0',
  },
  customAudio: {
    display: 'none', // Hide native audio control
  },
  avatar: {
    marginRight: '8px',
    width: '40px',
    height: '40px',
  },
  playButton: {
    marginRight: '8px',
    color: '#128C7E', // WhatsApp green color
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    width: '32px',
    height: '32px',
    padding: '4px',
  },
  speedButton: {
    marginLeft: '4px',
    color: '#333',
    fontSize: '0.75rem',
    padding: '2px 4px',
    minWidth: '35px',
    height: '22px',
    borderRadius: '11px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  audioInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '8px',
    height: '45px',
  },
  waveformContainer: {
    flexGrow: 1,
    height: '25px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  waveform: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  waveformBar: {
    width: '2px',
    marginRight: '1px',
    borderRadius: '1px',
    backgroundColor: '#b0b0b0',
    transition: 'height 0.2s ease-in-out',
  },
  waveformBarActive: {
    backgroundColor: '#128C7E', // WhatsApp green for played part
  },
  durationText: {
    fontSize: '0.75rem',
    color: '#888',
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
    pointerEvents: 'none',
  },
  waveformOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  speedText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },
  
  textContentItemEdited: {
    overflowWrap: "break-word",
    padding: "3px 120px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    // Create a new array instead of mutating the existing one
    if (messageIndex !== -1) {
      return state.map((m) => (m.id === newMessage.id ? newMessage : m));
    } else {
      return [...state, newMessage];
    }
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    // Create a new array to ensure the state change is detected
    if (messageIndex !== -1) {
      return state.map((m) => (m.id === messageToUpdate.id ? messageToUpdate : m));
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticket, ticketId, isGroup, contact }) => {

 
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const audioRef = useRef(null);
  const waveformRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false); // Para impedir conflito ao mover manualmente a barra de progresso
  const [waveformData, setWaveformData] = useState([]);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [activeAudioMessageId, setActiveAudioMessageId] = useState(null);
  const waveformBarsCount = 40; // Number of bars in the waveform

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  // Utility function to normalize text (remove accents and convert to lowercase)
  const normalizeText = (text) => {
    if (!text) return '';
    
    // Only normalize if the text might be a media type
    if (text === 'audio' || text === 'video' || text === 'image' || 
        text === 'document' || text === 'locationMessage' || text === 'contactMessage') {
      return text.toLowerCase();
    }
    
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .toLowerCase(); // Convert to lowercase
  };

  // Generate waveform when a new audio message is played
  const generateWaveform = (messageId) => {
    if (audioLoaded && activeAudioMessageId === messageId) return;
    
    // Generate a more realistic waveform pattern
    const data = [];
    for (let i = 0; i < waveformBarsCount; i++) {
      // Create a more natural pattern with higher values in the middle
      let val;
      if (i < waveformBarsCount / 3) {
        // First third - gradual increase
        val = 2 + Math.floor((i / (waveformBarsCount / 3)) * 12);
      } else if (i < (2 * waveformBarsCount) / 3) {
        // Middle third - higher values with small variations
        val = 10 + Math.floor(Math.random() * 8);
      } else {
        // Last third - gradual decrease
        val = 14 - Math.floor(((i - (2 * waveformBarsCount / 3)) / (waveformBarsCount / 3)) * 12);
      }
      
      // Add small random variations for a more natural look
      val += Math.floor(Math.random() * 4) - 2;
      
      // Ensure value is within bounds
      val = Math.max(2, Math.min(18, val));
      
      data.push(val);
    }
    
    setWaveformData(data);
    setActiveAudioMessageId(messageId);
    setAudioLoaded(true);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  // Remove the duplicate generateWaveform function in this useEffect
  useEffect(() => {
    if (audioRef.current && !audioLoaded) {
      audioRef.current.addEventListener('loadedmetadata', () => {
        if (activeAudioMessageId) {
          generateWaveform(activeAudioMessageId);
        }
      });
      
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('loadedmetadata', () => {});
        }
      };
    }
  }, [audioLoaded, activeAudioMessageId]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Update current time and duration
      const updateProgress = () => {
        if (!isSeeking) {
          setCurrentTime(audio.currentTime);
        }
      };

      const setAudioDuration = () => {
        setDuration(audio.duration);
      };

      const handleAudioEnd = () => {
        setIsPlaying(false);
        setCurrentTime(0);
      };

      audio.addEventListener('timeupdate', updateProgress);
      audio.addEventListener('loadedmetadata', setAudioDuration);
      audio.addEventListener('ended', handleAudioEnd);

      // Clean up listeners when unmounting
      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
        audio.removeEventListener('loadedmetadata', setAudioDuration);
        audio.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, [isSeeking]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom()
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on("connect", () => {
      console.log("Socket connected, joining chat:", ticket.id);
      socket.emit("joinChatBox", `${ticket.id}`);
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      console.log("Socket message received:", data);
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom()
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
    }
  };

  const formatTime = (timeInSeconds) => {
    if (!timeInSeconds) return "00:00";
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const togglePlayPause = (messageId) => {
    if (!audioRef.current) return;
    
    if (activeAudioMessageId !== messageId) {
      generateWaveform(messageId);
    }
    
    if (audioRef.current.paused) {
      audioRef.current.play().catch(error => {
        console.error("Error playing audio:", error);
      });
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSpeedChange = () => {
    if (!audioRef.current) return;
    
    const newRate = playbackRate === 1 ? 1.5 : playbackRate === 1.5 ? 2 : 1;
    setPlaybackRate(newRate);
    audioRef.current.playbackRate = newRate;
  };

  const handleWaveformClick = (e) => {
    if (!audioRef.current || !duration) return;
    
    const waveformEl = e.currentTarget;
    const rect = waveformEl.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const clickPositionRatio = offsetX / rect.width;
    const newTime = duration * clickPositionRatio;
    
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    
    // If paused, keep it that way after seeking
    if (audioRef.current.paused) {
      setIsPlaying(false);
    }
  };

  const handleWaveformMouseMove = (e) => {
    if (!waveformRef.current || !duration) return;
    
    const waveformEl = waveformRef.current;
    const rect = waveformEl.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    
    // Update position indicator
    const indicator = document.getElementById('progressIndicator');
    if (indicator) {
      indicator.style.left = `${offsetX}px`;
      indicator.style.display = 'block';
    }
  };

  const handleWaveformMouseLeave = () => {
    const indicator = document.getElementById('progressIndicator');
    if (indicator) {
      indicator.style.display = 'none';
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (message.mediaType === "locationMessage" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
     else if (message.mediaType === "contactMessage") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }

     

      return <VcardPreview contact={contact} numbers={obj[0].number}  />

     
    } 
    /*else if (message.mediaType === "multi_vcard") {
      console.log("multi_vcard")
      console.log(message)
    	
      if(message.body !== null && message.body !== "") {
        let newBody = JSON.parse(message.body)
        return (
          <>
            {
            newBody.map(v => (
              <VcardPreview contact={v.name} numbers={v.number} />
            ))
            }
          </>
        )
      } else return (<></>)
    }*/
    else if (message.mediaType === "image") {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    } else if (message.mediaType === "audio") {
      const percentagePlayed = duration ? (currentTime / duration) * 100 : 0;
      
      return (
        <Box className={classes.audioContainer} style={{
          backgroundColor: message.fromMe ? '#dcf8c6' : '#f0f0f0' // Match bubble color for sent messages
        }}>
          {/* Profile picture */}
          <Avatar src={!message.fromMe && contact.profilePicUrl} className={classes.avatar} />
          {/* Play/Pause button */}
          <IconButton
            onClick={() => togglePlayPause(message.id)}
            className={classes.playButton}
            size="large">
            {isPlaying && activeAudioMessageId === message.id ? 
              <Pause fontSize="small" /> : 
              <PlayArrow fontSize="small" />
            }
          </IconButton>
          {/* Waveform and duration */}
          <Box className={classes.audioInfo}>
            <Box 
              className={classes.waveformContainer} 
              onClick={handleWaveformClick}
              onMouseMove={handleWaveformMouseMove}
              onMouseLeave={handleWaveformMouseLeave}
              ref={waveformRef}
            >
              <Box className={classes.waveform}>
                {/* Generate waveform bars using the waveformData array */}
                {Array.from({ length: waveformBarsCount }).map((_, index) => {
                  // Use the pre-generated waveform data or fallback to pattern
                  const height = waveformData[index] ? 
                    waveformData[index] : 
                    // Fallback pattern if waveformData isn't ready
                    5 + Math.abs(Math.sin(index * 0.5) * 15);
                  
                  // Calculate if this bar should be active (played) or inactive
                  const barPosition = (index / waveformBarsCount) * 100;
                  const isActive = barPosition <= percentagePlayed && activeAudioMessageId === message.id;
                  
                  return (
                    <Box 
                      key={index}
                      className={clsx(
                        classes.waveformBar, 
                        { [classes.waveformBarActive]: isActive }
                      )}
                      style={{ height: `${height}px` }}
                    />
                  );
                })}
              </Box>
              {/* Progress indicator that shows on hover */}
              <div 
                id="progressIndicator" 
                className={classes.progressIndicator} 
                style={{ display: 'none' }}
              />
              {/* Transparent overlay to catch mouse events */}
              <div className={classes.waveformOverlay} />
            </Box>
            <Typography className={classes.durationText}>
              <span>{formatTime(currentTime || 0)}</span>
              <span>{formatTime(duration || 0)}</span>
            </Typography>
          </Box>
          {/* Playback speed button */}
          <Tooltip title="Velocidade de reprodução">
            <Button 
              onClick={handleSpeedChange} 
              className={classes.speedButton}
              variant="text"
            >
              <span className={classes.speedText}>{playbackRate}x</span>
            </Button>
          </Tooltip>
          {/* Hidden audio element */}
          <audio 
            ref={audioRef} 
            className={classes.customAudio}
            preload="metadata"
            onLoadedMetadata={() => generateWaveform(message.id)}
          >
            <source src={message.mediaUrl} type="audio/ogg" />
            <source src={message.mediaUrl} type="audio/mpeg" />
            <source src={message.mediaUrl} type="audio/mp3" />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        </Box>
      );
    } else if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 1) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };
  

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {

      let messageTicket = message.ticketId;
      let connectionName = message.ticket?.whatsapp?.name;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <center>
            <div className={classes.ticketNunberClosed}>
              Conversa encerrada: {format(parseISO(messagesList[index - 1].createdAt), "dd/MM/yyyy HH:mm:ss")}
            </div>

            <div className={classes.ticketNunberOpen}>
              Conversa iniciada: {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm:ss")}
            </div>
          </center>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {

   
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}

          {message.quotedMsg.mediaType === "audio"
            && (
              <div className={classes.downloadMedia}>
                <audio controls>
                  <source src={message.quotedMsg.mediaUrl} type="audio/ogg"></source>
                </audio>
              </div>
            )
          }
          {message.quotedMsg.mediaType === "video"
            && (
              <video
                className={classes.messageMedia}
                src={message.quotedMsg.mediaUrl}
                controls
              />
            )
          }
          {message.quotedMsg.mediaType === "application"
            && (
              <div className={classes.downloadMedia}>
                <Button
                  startIcon={<GetApp />}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={message.quotedMsg.mediaUrl}
                >
                  Download
                </Button>
              </div>
            )
          }
{/* 
          {message.quotedMsg.mediaType === "image"
            && (
              <ModalImageCors imageUrl={message.quotedMsg.mediaUrl} />)
            || message.quotedMsg?.body} */}

        </div>
      </div>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {

        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                    <path fill="#df3333" d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                  </svg> <span>Chamada de voz/vídeo perdida às {format(parseISO(message.createdAt), "HH:mm")}</span>
                </div>
              </div>
            </React.Fragment>
          );
        }

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageLeft}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}

                {/* aviso de mensagem apagado pelo contato */}
                {message.isDeleted && (
                  <div>
                    <span className={"message-deleted"}
                    >Essa mensagem foi apagada pelo contato &nbsp;
                      <Block
                        color="error"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    </span>
                  </div>
                )}

                {((message.mediaUrl || message.mediaType === "locationMessage" || message.mediaType === "contactMessage")
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message)}
                <div className={classes.textContentItem}>
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.body && (
                    // Only hide the message if it's EXACTLY a media type string
                    (!message.mediaType || 
                     (message.mediaType && message.body.toLowerCase() !== message.mediaType.toLowerCase())) && (
                      <MarkdownWrapper>
                        {message.mediaType === "locationMessage" ? null : message.body}
                      </MarkdownWrapper>
                    )
                  )}
                  <span className={classes.timestamp}>
				    {message.isEdited && <span>Editada </span>}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageRight}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {((message.mediaUrl || message.mediaType === "locationMessage" || message.mediaType === "contactMessage")
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                    [classes.textContentItemEdited]: message.isEdited,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {message.body && (
                    // Same improved logic for sent messages
                    (!message.mediaType || 
                     (message.mediaType && message.body.toLowerCase() !== message.mediaType.toLowerCase())) && (
                      <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    )
                  )}
                  <span className={classes.timestamp}>
                    {message.isEdited && <span>Editada </span>}
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Diga olá para seu novo contato!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
