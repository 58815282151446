import React, { useEffect, useState, useRef } from "react";

import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CreateIcon from '@mui/icons-material/Create';
import api from '../../services/api';

import { i18n } from "../../translate/i18n";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader, Grid, Divider } from "@mui/material";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: `1px solid ${theme.palette.divider}`, // replaced hardcoded border
		borderRight: `1px solid ${theme.palette.divider}`,
		borderBottom: `1px solid ${theme.palette.divider}`,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: `1px solid ${theme.palette.divider}`, // replaced hardcoded border
		backgroundColor: theme.palette.primary.main, // updated color from palette
		color: theme.palette.primary.contrastText,    // ensure contrast text color
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: theme.palette.background.default,
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	// New style for the edit button
	editButton: {
		fontSize: 12,
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.secondary.contrastText,
		borderColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			borderColor: theme.palette.secondary.dark,
		},
	},
	// New style for the customer group section (increases contrast)
	groupSection: {
		backgroundColor: theme.palette.background.paper, // use standard background
		border: `2px solid ${theme.palette.primary.main}`,  // primary color border for contrast
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(2),
		marginTop: theme.spacing(3),
		boxShadow: theme.shadows[3],
	},
	groupItem: {
		padding: theme.spacing(1),
		margin: theme.spacing(1, 0),
		borderRadius: theme.shape.borderRadius,
		// Alternating color for even and odd groups
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.background.default,
		},
		'&:nth-of-type(odd)': {
			backgroundColor: `${theme.palette.primary.light}10`, // Very light version of primary color
		},
		border: `1px solid ${theme.palette.divider}`,
	},
	groupLabel: {
		fontWeight: 'bold',
		color: theme.palette.text.secondary,
		fontSize: '0.75rem',
	},
	groupValue: {
		fontSize: '0.9rem',
		wordBreak: 'break-word',
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [grupos, setGrupos] = useState([]);
  
  // New ref to track mount status
  const isMounted = useRef(true);

	useEffect(() => {
    // Set mounted flag for this effect
		let isActive = true;
		setOpenForm(false);
		if (contact && contact.number) {
			api
				.get(`/api/clientesmanagment/grupos/${contact.number}`)
				.then((response) => {
					if (isActive) {
						
						setGrupos(response.data.grupos);
					}
				});
		}
		return () => {
			isActive = false;
		};
	}, [open, contact]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
        <>
            <Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="right"
				open={open}
				PaperProps={{ style: { position: "absolute" } }}
				BackdropProps={{ style: { position: "absolute" } }}
				ModalProps={{
					container: document.getElementById("drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.header}>
					<IconButton onClick={handleDrawerClose} size="large">
						<CloseIcon />
					</IconButton>
					<Typography style={{ justifySelf: "center" }}>
						{i18n.t("contactDrawer.header")}
					</Typography>
				</div>
				{loading ? (
					<ContactDrawerSkeleton classes={classes} />
				) : (
					<div className={classes.content}>
						<Paper square variant="outlined" className={classes.contactHeader}>
							<CardHeader
								onClick={() => {}}
								style={{ cursor: "pointer", width: '100%' }}
								titleTypographyProps={{ noWrap: true }}
								subheaderTypographyProps={{ noWrap: true }}
								avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" style={{ width: 60, height: 60 }} />}
								title={
									<>
										<Typography onClick={() => setOpenForm(true)}>
											{contact.name}
											<CreateIcon style={{fontSize: 16, marginLeft: 5}} />
										</Typography>
									</>
								}
								subheader={
									<>
										<Typography style={{fontSize: 12}}>
											<Link href={`tel:${contact.number}`}>{contact.number}</Link>
										</Typography>
										<Typography style={{fontSize: 12}}>
											<Link href={`mailto:${contact.email}`}>{contact.email}</Link>
										</Typography>
									</>
								}
							/>
							<Button
								variant="outlined"
								className={classes.editButton}
								onClick={() => setModalOpen(!openForm)}
							>
								{i18n.t("contactDrawer.buttons.edit")}
							</Button>
							{(contact.id && openForm) && <ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />}
						</Paper>
						{/* Exibir os grupos se existirem */}
						{grupos.length > 0 && (
							<Paper square variant="outlined" className={`${classes.contactDetails} ${classes.groupSection}`}>
								<Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: 12 }}>
								⭐️ Grupos do Cliente ({grupos.length})
								</Typography>
								{grupos.map((grupo, index) => (
									<div key={index} className={classes.groupItem}>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Typography className={classes.groupLabel}>
													Grupo:
												</Typography>
												<Typography className={classes.groupValue}>
													 🟢 {grupo.grupo}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography className={classes.groupLabel}>
													ID:
												</Typography>
												<Typography className={classes.groupValue}>
													{grupo.idGrupo}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography className={classes.groupLabel}>
													Vencimento:
												</Typography>
												<Typography className={classes.groupValue}>
													{new Date(grupo.dataVencimento).toLocaleDateString()}
												</Typography>
											</Grid>
										</Grid>
									</div>
								))}
							</Paper>
						)}
						<Paper square variant="outlined" className={classes.contactDetails}>
							<Typography variant="subtitle1" style={{marginBottom: 10}}>
								{i18n.t("ticketOptionsMenu.appointmentsModal.title")}
							</Typography>
							<ContactNotes ticket={ticket} />
						</Paper>
						<Paper square variant="outlined" className={classes.contactDetails}>
							<ContactModal
								open={modalOpen}
								onClose={() => setModalOpen(false)}
								contactId={contact.id}
							></ContactModal>
							<Typography variant="subtitle1">
								{i18n.t("contactDrawer.extraInfo")}
							</Typography>
							{contact?.extraInfo?.map(info => (
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel>{info.name}</InputLabel>
									<Typography component="div" noWrap style={{ paddingTop: 2 }}>
										<MarkdownWrapper>{info.value}</MarkdownWrapper>
									</Typography>
								</Paper>
							))}
						
						</Paper>
						
					</div>
				)}
			</Drawer>
        </>
    );
};

export default ContactDrawer;
