import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ColorModeContext from "./layout/themeContext";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();

    
    const [mode, setMode] = useState('light');

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => ("light"));
            },
        }),
        []
    );

    const theme = createTheme({
        scrollbarStyles: {
            "&::-webkit-scrollbar": {
                width: '8px',
                height: '8px',
            },
            "&::-webkit-scrollbar-thumb": {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: "rgb(236, 64, 122)",
            },
        },
        scrollbarStylesSoft: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
            },
        },  
        palette: {
            mode: mode,
            background: { default: mode === "light" ? "#12172F" : "#333333" },
            primary: { main: mode === "light" ? "rgb(236, 64, 122)" : "#FFFFFF" },
            textPrimary: mode === "light" ? "#FFFFFF" : "#FFFFFF",
            borderPrimary: mode === "light" ? "rgb(236, 64, 122)" : "#FFFFFF",
            dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
            light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
            tabHeaderBackground: mode === "light" ? "rgb(17, 25, 54)" : "#666",
            optionsBackground: mode === "light" ? "#fafafa" : "#333",
            options: mode === "light" ? "#fafafa" : "#666",
            fontecor: mode === "light" ? "#128c7e" : "#fff",
            fancyBackground: mode === "light" ? "#030614" : "#333",
            bordabox: mode === "light" ? "#eee" : "#333",
            newmessagebox: mode === "light" ? "#12172F" : "#333",
            inputdigita: mode === "light" ? "#12172F" : "#666",
            contactdrawer: mode === "light" ? "#fff" : "#666",
            announcements: mode === "light" ? "#ededed" : "#333",
            login: mode === "light" ? "#12172F" : "#1C1C1C",
            announcementspopover: mode === "light" ? "#fff" : "#666",
            chatlist: mode === "light" ? "#eee" : "#666",
            boxlist: mode === "light" ? "#ededed" : "#666",
            boxchatlist: mode === "light" ? "#ededed" : "#333",
            total: mode === "light" ? "#fff" : "#222",
            messageIcons: mode === "light" ? "rgb(17, 25, 54)" : "#F3F3F3",
            inputBackground: mode === "light" ? "#FFFFFF" : "#333",
            barraSuperior: mode === "light" ? "#030614" : "#666",
            boxticket: mode === "light" ? "rgb(17, 25, 54)" : "#666",
            campaigntab: mode === "light" ? "#ededed" : "#666",
            mediainput: mode === "light" ? "#ededed" : "#1c1c1c",
        },
        mode,
    }, locale)

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);



    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <Routes />
                    </QueryClientProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
