import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
} from "@mui/material";




import { makeStyles } from '@mui/styles';




import AccountCircle from "@mui/icons-material/AccountCircle";

import SvgIcon from "@mui/material/SvgIcon";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import NotificationsVolume from "../components/NotificationsVolume";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";

import { ReactComponent as LogbarIcon } from "../assets/logbar.svg";
import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";

import { useDate } from "../hooks/useDate";

import ColorModeContext from "../layout/themeContext";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh", // Usa a altura total da viewport
    backgroundColor: theme.palette.fancyBackground,
    overflow: "auto", // Permite scroll no conteúdo principal, se necessário
    '& .MuiButton-outlinedPrimary': {
      color: theme.mode === 'light' ? '#FFF' : '#FFF',
      backgroundColor: theme.mode === 'light' ? 'rgb(236, 64, 122)' : '#1c1c1c', 
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.mode === 'light' ? 'rgb(236, 64, 122)' : '#FFF',
    }
  },
  avatar: {
    width: "100%",
  },
  toolbar: {
    paddingRight: 24,
    
    position: "fixed", // Fixar a barra de ferramentas no topo da tela
    alignItems: "center",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer + 1, // Garantir que a barra fique acima do resto do conteúdo
    color: theme.palette.dark.main,
    background: theme.palette.barraSuperior,
  },
 
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    marginTop: 60, // maintain top offset
    height: "calc(100vh - 60px)", // limit drawer height
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#030614 !important',
    color: 'rgb(213, 217, 233)',
    [theme.breakpoints.down('md')]: {
      width: "75%",
      marginTop: 0,
      height: "100vh", // full height on mobile
    },
    ...theme.scrollbarStylesSoft,
    whiteSpace: "nowrap",
  },

  drawerPaperClose: {
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      width: 0, // Ocultar o menu lateral em dispositivos móveis
    },
    
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      display: "none"
    }
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
    color: "white",
  },


  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",

  },


   container: {
    zIndex: 1,
    backgroundColor: '#0A0F23',
    minWidth: '1%',
    width: 'calc(100% - 260px)',
    minHeight: 'calc(100vh - 64px)',
    height: "100%", // Garante que o conteúdo ocupe a altura do root
    overflow: "auto", // Permite scroll no conteúdo principal, se necessário
    flexGrow: 1,
    flex: 1,
    marginTop: '0px',
    marginRight: '20px',
    marginLeft: '0px',
    padding: '20px',
    borderRadius: '70px 70px 0px 0px',
    transition: 'margin 400ms cubic-bezier(0, 0, 0.2, 1)',
    
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
      marginLeft: '0px',
      padding: '0px',
      marginTop: '10px',
      height: 'calc(100vh - 10px)', // 64px for toolbar + 10px for marginTop
      borderRadius: '70px 70px 0px 0px',
      
    },
    ...theme.scrollbarStyles,
    

  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    color: 'rgb(213, 217, 233)', // Define a cor do texto nos itens do Drawer
    ...theme.scrollbarStyles,
    //overflow: 'hidden !important',
  },
  logo: {
    width: "80%",
    height: "auto",
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      width: "auto",
      height: "80%",
      maxWidth: 180,
    },
    logo: theme.logo
  },

 

  svgIcon: {
    
    color: 'rgb(236, 64, 122)', // Cor inicial do ícone

    '&:hover': {
      backgroundColor: 'rgb(236, 64, 122)', // Cor do background ao passar o mouse
      color: 'rgb(253, 232, 239)', // Cor do ícone ao passar o mouse
      transition: 'all 0.3s ease', // Adicionar transição suave
    },
  },
  
}));

const LoggedInLayout = ({ children, themeToggle }) => {
  const classes = useStyles();
  
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { colorMode } = useContext(ColorModeContext);
 


  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);

  const { dateToClient } = useDate();


  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRefreshPage = () => {
    window.location.reload(false);
  }

  const handleMenuItemClick = () => {
    const { innerWidth: width } = window;
    if (width <= 600) {
      setDrawerOpen(false);
    }
  };

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
  }

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root} >
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar position="fixed" className={clsx(classes.toolbar, drawerOpen && classes.appBarShift)}>
     
        

        <Toolbar variant="dense" className={classes.toolbar}>
        

          {/* Adicionando o SVG como o logo no canto esquerdo */}
          {!isMobile && ( 
     
     <LogbarIcon width="300" height="100" className={classes.logo}/>


             
            )}

  <Typography
    component="h2"
    variant="h6"
    color="inherit"
    noWrap
    className={classes.title}
  >
    {/* Se desejar manter o título em texto também, pode inseri-lo aqui */}
    
    <IconButton
      edge="start"
      variant="contained"
      aria-label="open drawer"
      // Usando a função toggleDrawer para alternar o estado
      onClick={toggleDrawer}
      className={classes.menuButton}
      size="large">


            <SvgIcon className={classes.svgIcon}> <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path d="M4 6l16 0"></path>
      <path d="M4 12l16 0"></path>
      <path d="M4 18l16 0"></path>
    </svg></SvgIcon>
          </IconButton>
  </Typography>


       

          <NotificationsVolume
            setVolume={setVolume}
            volume={volume}
          />

          

          {user.id && <NotificationsPopOver volume={volume} />}

          

          <ChatPopover />

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              style={{ color: "white" }}
              size="large">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"} // Responsividade no menu lateral
        open={drawerOpen}
        onClose={toggleDrawer}
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
      >
        
        <Divider />
        
        <List className={classes.containerWithScroll}>
       
          <MainListItems drawerClose={drawerClose} collapsed={!drawerOpen} />
        </List>
        
        <Divider />
      </Drawer>
      <main className={classes.container}>
        <div className={classes.appBarSpacer} />

       {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;