import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Switch } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, Legend } from 'recharts';
import api from '../../services/api';
// ...certifique-se de ter instalado 'react-chartjs-2' e 'chart.js'...



const dummyRevenueEvolution = [
	{ period: 'Jan', revenue: 4000 },
	{ period: 'Fev', revenue: 4500 },
	{ period: 'Mar', revenue: 4700 },
	{ period: 'Abr', revenue: 5000 },
	{ period: 'Mai', revenue: 5200 },
	{ period: 'Jun', revenue: 5300 },
	{ period: 'Jul', revenue: 5500 },
	{ period: 'Ago', revenue: 5700 },
	{ period: 'Set', revenue: 5800 },
	{ period: 'Out', revenue: 6000 },
	{ period: 'Nov', revenue: 6200 },
	{ period: 'Dez', revenue: 6500 },
];

const dummyPaymentMethods = [
	{ name: 'PayPal', value: 60000 },
	{ name: 'Pix', value: 30000 },
	{ name: 'Transferência', value: 20000 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const dummyGroupAnalysis = [
	{ group: 'Grupo A', revenue: 25000, payments: 50 },
	{ group: 'Grupo B', revenue: 20000, payments: 40 },
	{ group: 'Grupo C', revenue: 15000, payments: 30 },
];

const dummyPayments = [
	{ date: '2023-10-01', group: 'Grupo A', payer: 'João', value: 100, method: 'Cartão' },
	{ date: '2023-09-25', group: 'Grupo B', payer: 'Maria', value: 150, method: 'Boleto' },
	// ...mais dados dummy...
];

const dummyRenewalIndicators = {
	renewalRate: '85%',
	avgDaysBetween: 30,
	forecastedRevenue: 70000
};

const MonthlyReportPage = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = ('0' + (new Date().getMonth() + 1)).slice(-2);
  const months = [
    { value: '01', name: 'Janeiro' },
    { value: '02', name: 'Fevereiro' },
    { value: '03', name: 'Março' },
    { value: '04', name: 'Abril' },
    { value: '05', name: 'Maio' },
    { value: '06', name: 'Junho' },
    { value: '07', name: 'Julho' },
    { value: '08', name: 'Agosto' },
    { value: '09', name: 'Setembro' },
    { value: '10', name: 'Outubro' },
    { value: '11', name: 'Novembro' },
    { value: '12', name: 'Dezembro' }
  ];

  // Reintroduz o filtro por período
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(''); // deixa vazio para usar o filtro período
  const [selectedPeriod, setSelectedPeriod] = useState('ultimoAno');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [financialSummary, setFinancialSummary] = useState({
    totalRevenue: 0,
    currentMonthRevenue: 0,
    totalCredits: 0,
    paymentsCount: 0
  });
  const [revenueEvolution, setRevenueEvolution] = useState(dummyRevenueEvolution);
  const [paymentMethods, setPaymentMethods] = useState(dummyPaymentMethods);
  const [groupAnalysis, setGroupAnalysis] = useState(dummyGroupAnalysis);
  const [payments, setPayments] = useState(dummyPayments);
  const [renewalIndicators, setRenewalIndicators] = useState(dummyRenewalIndicators);

  // Exemplo de chamada à API para atualizar filtros (dummy)
  useEffect(() => {
    // api.get('/api/relatorio/dashboard', { params: { year: selectedYear, period: selectedPeriod, group: selectedGroup, method: selectedMethod } })
    //   .then(response => {
    //     // setFinancialSummary, setRevenueEvolution, etc...
    //   });
  }, [selectedYear, selectedGroup, selectedMethod]);

  // Busca dados do resumo financeiro do backend
  useEffect(() => {
    const fetchFinancialSummary = async () => {
      try {
        const params = new URLSearchParams();
        params.append('year', selectedYear.toString());
        if (selectedGroup) params.append('group', selectedGroup);
        if (selectedMethod) params.append('method', selectedMethod);
        // Se não houver mês selecionado, usa o filtro de período
        if (selectedMonth) {
          params.append('month', `${selectedYear}-${selectedMonth}`);
        } else {
          params.append('period', selectedPeriod);
        }
        const response = await api.get(`/api/relatorio/mensal/summary?${params.toString()}`);
        const monthsData = response.data.months; // Cada item: { monthIndex, totalRevenue }
        const totalRevenue = monthsData.reduce((sum, m) => sum + m.totalRevenue, 0);
        let currentMonthRevenue = 0;
        if (selectedMonth) {
          const monthIndex = parseInt(selectedMonth, 10);
          currentMonthRevenue = monthsData.find(m => m.monthIndex === monthIndex)?.totalRevenue || 0;
        } else {
          const currentM = new Date().getMonth() + 1;
          currentMonthRevenue = monthsData.find(m => m.monthIndex === currentM)?.totalRevenue || 0;
        }
        setFinancialSummary(prev => ({
          ...prev,
          totalRevenue,
          currentMonthRevenue
        }));
      } catch (error) {
        console.error("Erro ao buscar resumo financeiro:", error);
      }
    };
    fetchFinancialSummary();
  }, [selectedYear, selectedGroup, selectedMethod, selectedMonth, selectedPeriod]);

  // Atualiza evolução da receita com dados do backend
  useEffect(() => {
    const fetchRevenueEvolution = async () => {
      try {
        const response = await api.get(`/api/relatorio/mensal/evolution?year=${selectedYear}`);
        setRevenueEvolution(response.data.evolution); // response.data.evolution esperado como array
      } catch (error) {
        console.error("Erro ao buscar evolução da receita:", error);
      }
    };
    fetchRevenueEvolution();
  }, [selectedYear]);

  // Atualiza métodos de pagamento com dados do backend
  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await api.get(`/api/relatorio/mensal/payment-methods?year=${selectedYear}`);
        setPaymentMethods(response.data.methods); // response.data.methods esperado como array
      } catch (error) {
        console.error("Erro ao buscar métodos de pagamento:", error);
      }
    };
    fetchPaymentMethods();
  }, [selectedYear]);

  // Atualiza análise de grupos com dados do backend
  useEffect(() => {
    const fetchGroupAnalysis = async () => {
      try {
        const response = await api.get(`/api/relatorio/mensal/group-analysis?year=${selectedYear}`);
        setGroupAnalysis(response.data.groups); // response.data.groups esperado como array
      } catch (error) {
        console.error("Erro ao buscar análise de grupos:", error);
      }
    };
    fetchGroupAnalysis();
  }, [selectedYear]);

  // Atualiza pagamentos recentes com base no backend (quando um mês é selecionado)
  useEffect(() => {
    const fetchRecentPayments = async () => {
      if (selectedMonth) {
        try {
          const monthParam = `${selectedYear}-${selectedMonth}`;
          const response = await api.get(`/api/relatorio/mensal/detalhado?month=${monthParam}`);
          setPayments(response.data.pagamentos); // response.data.pagamentos esperado como array
        } catch (error) {
          console.error("Erro ao buscar pagamentos recentes:", error);
        }
      }
    };
    fetchRecentPayments();
  }, [selectedYear, selectedMonth]);

  // Atualiza indicadores de renovação com dados do backend
  useEffect(() => {
    const fetchRenewalIndicators = async () => {
      try {
        const response = await api.get(`/api/relatorio/mensal/renewal-indicators?year=${selectedYear}`);
        setRenewalIndicators(response.data); // resposta esperada com os indicadores
      } catch (error) {
        console.error("Erro ao buscar indicadores de renovação:", error);
      }
    };
    fetchRenewalIndicators();
  }, [selectedYear]);

  // Nova chamada à API para atualizar todos os dados do dashboard
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const params = {
          year: selectedYear,
          group: selectedGroup,
          method: selectedMethod,
        };
        if (selectedMonth) {
          params.month = `${selectedYear}-${selectedMonth}`;
        } else {
          params.period = selectedPeriod;
        }
        const response = await api.get('/api/relatorio/dashboard', { params });
        setFinancialSummary(response.data.summary);
        setRevenueEvolution(response.data.evolution);
        setPaymentMethods(response.data.paymentMethods);
        setGroupAnalysis(response.data.groupAnalysis);
        setPayments(response.data.payments);
        setRenewalIndicators(response.data.renewalIndicators);
      } catch (error) {
        console.error("Erro ao buscar dados do dashboard:", error);
      }
    };
    fetchDashboardData();
  }, [selectedYear, selectedGroup, selectedMethod, selectedMonth, selectedPeriod]);

  return (
    <Container style={{ padding: '20px' /* removido: background e color, permitindo usar o padrão do projeto */ }}>
      <Typography variant="h4" gutterBottom>Dashboard Financeiro</Typography>
	  
	  {/* Filtros Globais */}
      <Grid container spacing={2} alignItems="center">
		<Grid item xs={12} md={2}>
		  <FormControl fullWidth>
			<InputLabel>Ano</InputLabel>
			<Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
			  <MenuItem value={currentYear}>{currentYear}</MenuItem>
			  <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
			  <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
			</Select>
		  </FormControl>
		</Grid>
		<Grid item xs={12} md={2}>
		  <FormControl fullWidth>
			<InputLabel>Mês</InputLabel>
			<Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
			  <MenuItem value="">-- Nenhum --</MenuItem>
			  {months.map(month => (
				<MenuItem key={month.value} value={month.value}>{month.name}</MenuItem>
			  ))}
			</Select>
		  </FormControl>
		</Grid>
		{/* Filtro Periodo reintroduzido */}
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>Período</InputLabel>
            <Select value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)}>
              <MenuItem value="ultimoMes">Último Mês</MenuItem>
              <MenuItem value="ultimos3meses">Últimos 3 Meses</MenuItem>
              <MenuItem value="ultimoAno">Último Ano</MenuItem>
            </Select>
          </FormControl>
        </Grid>
		<Grid item xs={12} md={2}>
		  <TextField label="Grupo" value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)} fullWidth />
		</Grid>
		<Grid item xs={12} md={2}>
		  <TextField label="Método" value={selectedMethod} onChange={(e) => setSelectedMethod(e.target.value)} fullWidth />
		</Grid>
		<Grid item xs={12} md={2}>
		  <Button variant="contained" color="primary" fullWidth>Filtrar</Button>
		</Grid>
	  </Grid>
	  
      {/* Cards de Resumo Financeiro */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={3}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Receita Total</Typography>
            <Typography variant="h4">R$ {financialSummary.totalRevenue}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Receita Mês Atual</Typography>
            <Typography variant="h4">R$ {financialSummary.currentMonthRevenue}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Créditos Adicionais</Typography>
            <Typography variant="h4">R$ {financialSummary.totalCredits}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Pagamentos Realizados</Typography>
            <Typography variant="h4">{financialSummary.paymentsCount}</Typography>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Gráficos de Evolução e Distribuição */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Evolução da Receita</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={revenueEvolution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="revenue" stroke="#8884d8" strokeWidth={2} />
                {/* Linha de tendência pode ser adicionada */}
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Métodos de Pagamento</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie data={paymentMethods} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} label>
                  {paymentMethods.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Análise de Grupos */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Análise de Grupos</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={groupAnalysis}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="group" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="revenue" fill="#82ca9d" name="Receita" />
                <Bar dataKey="payments" fill="#8884d8" name="Pagamentos" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Calendário de Vencimentos (Placeholder) */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Calendário de Vencimentos</Typography>
            {/* Aqui pode ser integrado um componente de calendário real */}
            <Typography>Visualização de datas futuras com código de cores (ex: proximidade de vencimento) agrupadas por grupos.</Typography>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Tabela de Pagamentos Recentes */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Pagamentos Recentes</Typography>
			{/* Campo de busca */}
			<TextField label="Buscar" fullWidth style={{ marginBottom: '10px' }} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Grupo</TableCell>
                    <TableCell>Pagador</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Método</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment, index) => (
                    <TableRow key={index}>
                      <TableCell>{payment.dataPagamento}</TableCell>
                      <TableCell>{payment.nomeGrupo}</TableCell>
                      <TableCell>{payment.nomePagador}</TableCell>
                      <TableCell>R$ {payment.mesesAdicionados * 20}</TableCell>
                      <TableCell>{payment.metodoPagamento}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>Próxima Página</Button>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Indicadores de Renovação */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6">Indicadores de Renovação</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography>Taxa de Renovação: {renewalIndicators.renewalRate}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Tempo Médio entre Pagamentos: {renewalIndicators.avgDaysBetween} dias</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Receita Futura Prevista: R$ {renewalIndicators.forecastedRevenue}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
	  
	  {/* Botões de Exportação */}
	  <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
		<Grid item xs={6} md={3}>
		  <Button variant="outlined" color="primary" fullWidth>Exportar PDF</Button>
		</Grid>
		<Grid item xs={6} md={3}>
		  <Button variant="outlined" color="primary" fullWidth>Exportar Excel</Button>
		</Grid>
	  </Grid>
	  
	  {/* ...existing code, se necessário... */}
    </Container>
  );
};

export default MonthlyReportPage;
